<div class="fixed bottom-6 left-3 z-50">
  <a class="cursor-pointer" target="_blank" href="https://wa.me/+971557827461">
    <img
      class="w-10"
      src="assets/homePage/whatsapp.png"
      loading="lazy"
      alt="Chat Icon"
    />
  </a>
</div>
