<mat-sidenav-container [hasBackdrop]="true">
  <mat-sidenav
    #sidenavNav
    id="sidenavNav"
    mode="over"
    [(opened)]="isSidenavOpen"
    [style.width.px]="250"
    [fixedInViewport]="true"
    fixedTopGap="70"
    [attr.aria-hidden]="!isSidenavOpen"
  >
    <div class="text-center" [ngClass]="activeLang === 'ar' ? 'cairo' : 'roboto'"
      [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'">
      <a
        class="navItems"
        [routerLink]="[activeLang, 'home']"
        [attr.href]="['/' + activeLang, 'home'].join('/')"
        [attr.tabindex]="isSidenavOpen ? 0 : -1"
        (click)="navigateToRoute()"
      >
        {{ "homePage" | transloco }}
      </a>
      <svg
        class="center mt-5"
        width="132"
        height="2"
        viewBox="0 0 132 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        [attr.tabindex]="isSidenavOpen ? 0 : -1"
      >
        <line
          y1="1.5"
          x2="132"
          y2="1.5"
          stroke="url(#paint0_linear_22_158)"
          stroke-width="3"
        />
        <defs>
          <linearGradient
            id="paint0_linear_22_158"
            x1="0"
            y1="3"
            x2="132"
            y2="3"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#49AEAC" />
            <stop offset="0.52" stop-color="white" />
            <stop offset="1" stop-color="#49AEAC" />
          </linearGradient>
        </defs>
      </svg>
      <a
        class="navItems"
        [routerLink]="[activeLang, 'projects']"
        [attr.href]="['/' + activeLang, 'projects'].join('/')"
        [attr.tabindex]="isSidenavOpen ? 0 : -1"
        (click)="navigateToRoute()"
      >
        <span style="margin-top: 5px">{{ "projects" | transloco }}</span>
      </a>
      <svg
        class="center mt-5"
        width="132"
        height="2"
        viewBox="0 0 132 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        [attr.tabindex]="isSidenavOpen ? 0 : -1"
      >
        <line
          y1="1.5"
          x2="132"
          y2="1.5"
          stroke="url(#paint0_linear_22_158)"
          stroke-width="3"
        />
        <defs>
          <linearGradient
            id="paint0_linear_22_158"
            x1="0"
            y1="3"
            x2="132"
            y2="3"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#49AEAC" />
            <stop offset="0.52" stop-color="white" />
            <stop offset="1" stop-color="#49AEAC" />
          </linearGradient>
        </defs>
      </svg>
      <a
        class="navItems"
        [routerLink]="[activeLang, 'services']"
        [attr.href]="['/' + activeLang, 'services'].join('/')"
        [attr.tabindex]="isSidenavOpen ? 0 : -1"
        (click)="navigateToRoute()"
      >
        <span style="margin-top: 5px">{{ "services" | transloco }}</span>
      </a>
      <svg
        class="center mt-5"
        width="132"
        height="2"
        viewBox="0 0 132 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        [attr.tabindex]="isSidenavOpen ? 0 : -1"
      >
        <line
          y1="1.5"
          x2="132"
          y2="1.5"
          stroke="url(#paint0_linear_22_158)"
          stroke-width="3"
        />
        <defs>
          <linearGradient
            id="paint0_linear_22_158"
            x1="0"
            y1="3"
            x2="132"
            y2="3"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#49AEAC" />
            <stop offset="0.52" stop-color="white" />
            <stop offset="1" stop-color="#49AEAC" />
          </linearGradient>
        </defs>
      </svg>
      <a
        class="navItems"
        [routerLink]="[activeLang, 'blogs']"
        [attr.href]="['/' + activeLang, 'blogs'].join('/')"
        [attr.tabindex]="isSidenavOpen ? 0 : -1"
        (click)="navigateToRoute()"
      >
        <span style="margin-top: 5px">{{ "suggestedBlogs" | transloco }}</span>
      </a>
      <svg
        class="center mt-5"
        width="132"
        height="2"
        viewBox="0 0 132 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        [attr.tabindex]="isSidenavOpen ? 0 : -1"
      >
        <line
          y1="1.5"
          x2="132"
          y2="1.5"
          stroke="url(#paint0_linear_22_158)"
          stroke-width="3"
        />
        <defs>
          <linearGradient
            id="paint0_linear_22_158"
            x1="0"
            y1="3"
            x2="132"
            y2="3"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#49AEAC" />
            <stop offset="0.52" stop-color="white" />
            <stop offset="1" stop-color="#49AEAC" />
          </linearGradient>
        </defs>
      </svg>
      <a
        class="navItems"
        [routerLink]="[activeLang, 'contact']"
        [attr.href]="['/' + activeLang, 'contact'].join('/')"
        [attr.tabindex]="isSidenavOpen ? 0 : -1"
        (click)="navigateToRoute()"
      >
        <span style="margin-top: 5px">{{ "contact" | transloco }}</span>
      </a>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
  <app-fab-whats-app-icon></app-fab-whats-app-icon>
  <app-loading></app-loading>