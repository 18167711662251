<div class="mt-28" [ngClass]="activeLang === 'ar' ? 'cairo' : 'roboto'">
  <div
    appFadeInUp
    class="flex items-center justify-center"
    [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
  >
    <p class="text-center font-semibold inline-block text-3xl">
      {{ "suggestedBlogs" | transloco }}
      <svg
        class="mt-1"
        width="50"
        height="2"
        viewBox="0 0 50 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="1" x2="50" y2="1" stroke="#49AEAC" stroke-width="2" />
      </svg>
    </p>
  </div>

  <div
    class="px-5 lg:px-10 xl:px-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 items-center gap-5 lg:gap-10 xl:gap-14 mt-10"
  >
    <div
      class="shadow-lg rounded-t-xl h-full"
      *ngFor="let item of blogs"
    >
      <a
        class="pt-5 hover:text-[#49AEAC]"
        [routerLink]="['/', activeLang, 'blog', item.seoURL]"
        [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
      >
        <div class="w-full h-64 mx-auto overflow-hidden">
          <img
            *ngIf="item.image.url !== 'none'"
            [src]="item.image.url"
            loading="lazy"
            alt="Image 1"
            class="center object-cover w-full h-full grayscale-0 hover:grayscale"
          />
        </div>
        <div class="p-2 mt-3">
          <p class="w-full font-bold text-center">
            {{ item.title[activeLang] }}
          </p>
        </div>
      </a>
    </div>
  </div>
</div>

<div class="my-60"></div>
