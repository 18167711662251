import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslocoService } from '@ngneat/transloco';
import { GeneralService } from 'app/services/general.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit, OnDestroy {
  contactForm: FormGroup;
  activeLang: string = 'ar';
  private langChangesSubscription!: Subscription;
  isSubmitting = false;  // New variable to track the loading state
  submissionMessage = ''; // New variable for the success message

  formErrors = {
    fullName: '',
    email: '',
    message: '',
  };

  validationMessages = {
    en: {
      fullName: {
        required: 'Full Name is required.',
        minlength: 'Full Name must be at least 5 characters long.'
      },
      email: {
        required: 'Email is required.',
        email: 'Email must be a valid email address.',
        minlength: 'Email must be at least 7 characters long.'
      },
      message: {
        required: 'Message is required.',
        minlength: 'Message must be at least 5 characters long.'
      }
    },
    ar: {
      fullName: {
        required: 'الاسم الكامل مطلوب.',
        minlength: 'يجب أن يكون الاسم الكامل 5 أحرف على الأقل.'
      },
      email: {
        required: 'البريد الإلكتروني مطلوب.',
        email: 'يجب أن يكون البريد الإلكتروني عنوان بريد إلكتروني صالح.',
        minlength: 'يجب أن يكون البريد الإلكتروني 7 أحرف على الأقل.'
      },
      message: {
        required: 'الرسالة مطلوبة.',
        minlength: 'يجب أن تكون الرسالة 5 أحرف على الأقل.'
      }
    }
  };

  constructor(
    private _translocoService: TranslocoService,
    private fb: FormBuilder,
    private gs: GeneralService) { }

  ngOnInit(): void {
    this.getActiveLang();
    this.createForm();
  }

  createForm() {
    this.contactForm = this.fb.group({
      fullName: ['', [Validators.required, Validators.minLength(5)]],
      email: ['', [Validators.required, Validators.email, Validators.minLength(7)]],
      message: ['', [Validators.required, Validators.minLength(5)]]
    });
    this.contactForm.valueChanges.subscribe(data => this.onValueChanged(data));
    this.onValueChanged(); // (re)set form validation messages
  }

  onValueChanged(data?: any) {
    if (!this.contactForm) {
      return;
    }
    const form = this.contactForm;
    const messages = this.validationMessages[this.activeLang];
    for (const field in this.formErrors) {
      if (this.formErrors.hasOwnProperty(field)) {
        this.formErrors[field] = '';
        const control = form.get(field);
        if (control && control.dirty && !control.valid) {
          const fieldMessages = messages[field];
          for (const key in control.errors) {
            if (control.errors.hasOwnProperty(key)) {
              this.formErrors[field] += fieldMessages[key] + ' ';
            }
          }
        }
      }
    }
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.isSubmitting = true;  // Start loading
      const formData = { ...this.contactForm.value };
      this.gs.addItem('contact', formData).subscribe({
        next: (res) => {
          this.isSubmitting = false;
          this.submissionMessage = this.activeLang === 'ar'
            ? 'تم استلام رسالتك وسوف نتواصل معك في أقرب وقت ممكن'
            : 'Your message has been received. We will respond as soon as possible.';
          this.contactForm.reset();  // Reset the form after submission
        },
        error: (err) => {
          this.isSubmitting = false;
          this.submissionMessage = this.activeLang === 'ar'
            ? 'حدث خطأ أثناء الإرسال. حاول مرة أخرى.'
            : 'An error occurred. Please try again.';
        }
      });
    }
  }

  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }
}
