<div [ngClass]="activeLang === 'ar' ? 'cairo text-right' : 'roboto text-left'">
  <div class="grid md:flex w-full xl:px-32">
    <div class="block md:hidden w-full relative mt-20">
      <img
        appFadeInUp
        class="center min-h-[300px]"
        src="assets/homePage/hazem.webp"
        loading="lazy"
        width="80%"
        alt="Hazem_Image"
      />
      <div class="mask"></div>

      <img
        class="absolute top-0 right-0 -z-10 opacity-15"
        src="assets/homePage/77.png"
        loading="lazy"
        width="75%"
        alt="art"
      />
    </div>
    <div class="lg:w-1/2 px-2 lg:px-0 mt-8 md:mt-32 xl:mt-52">
      <div
        appFadeInUp
        [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
        [ngClass]="activeLang === 'en' ? 'lg:ml-6' : ''"
      >
        <div class="min-h-[220px]">
          <p class="text-[#49aeac] text-4xl">
            {{ "hello" | transloco }}
          </p>
          <p
            class="text-zinc-300 font-bold text-4xl lg:text-5xl xl:text-6xl py-2"
          >
            {{ "hazemSweed" | transloco }}
          </p>
          <p class="text-zinc-300 py-5 text-xl lg:text-2xl">
            {{ "myDigital" | transloco }}
            <span class="main_color font-bold"> {{ "space" | transloco }}</span>
          </p>
          <p class="text-zinc-300 text-base lg:text-xl xl:text-2xl">
            {{ "creative" | transloco
            }}<span class="main_color"> {{ "webDev" | transloco }}</span>
            {{ "basedTr" | transloco }} <br />
            {{ "passionate" | transloco }}
          </p>
        </div>
        <div class="flex items-center mt-5">
          <button
            [ngClass]="activeLang === 'ar' ? 'ml-2 sm:ml-5' : 'mr-5'"
            class="buttonsWidth relative px-10 py-2 h-9 bg-transparent border-2 border-[#49aeac] hover:text-[#020617] font-semibold rounded-full overflow-hidden"
            (click)="getElToViewPortFun('aboutMe')"
          >
            <span class="fill-animation"></span>
            {{ "aboutMe" | transloco }}
          </button>
          <button
            [ngClass]="activeLang === 'ar' ? 'ml-2 sm:ml-5' : ''"
            class="buttonsWidth bg-[#49aeac] font-semibold h-9 px-10 py-2 text-[#020617] rounded-full hover:bg-[#388887]"
            [routerLink]="['/', activeLang, 'contact']"
          >
            {{ "letsContact" | transloco }}
          </button>
        </div>
      </div>

      <img
        class="pracits2 -z-10 opacity-50 hidden lg:block"
        src="assets/homePage/5.png"
        loading="lazy"
        width="50%"
        alt="art"
      />
    </div>

    <div class="hidden md:block w-1/2 relative mt-20">
      <div>
        <img
          appFadeInUp
          class="center min-h-[300px]"
          src="assets/homePage/hazem.webp"
          loading="lazy"
          width="80%"
          alt="Hazem_Image"
        />
        <div class="mask"></div>
      </div>
      <img
        class="pracits -z-10 opacity-15"
        src="assets/homePage/77.png"
        loading="lazy"
        width="75%"
        alt="art"
      />
    </div>
  </div>
  <div
    class="w-full grid grid-cols-4 overflow-hidden items-center justify-around md:px-32 mt-14 lg:mt-28"
  >
    <div class="grid justify-center">
      <div class="yearsCircle"></div>
      <div class="-mt-[4.5rem] lg:-mt-[6rem] xl:-mt-[7.5rem] z-0">
        <p class="text-center text-2xl lg:text-3xl xl:text-4xl">
          +{{ statisticsNumbers[0].animatedValue | number : "1.0-0" }}
        </p>
        <p class="text-center lg:text-lg xl:text-xl">
          {{ "year" | transloco }}
        </p>
      </div>
    </div>
    <div class="grid justify-center">
      <div class="projectsCircle"></div>
      <div class="-mt-[4.5rem] lg:-mt-[6rem] xl:-mt-[7.5rem] z-0">
        <p class="text-center text-2xl lg:text-3xl xl:text-4xl">
          +{{ statisticsNumbers[1].animatedValue | number : "1.0-0" }}
        </p>
        <p class="text-center lg:text-lg xl:text-xl">
          {{ "project" | transloco }}
        </p>
      </div>
    </div>
    <div class="grid justify-center">
      <div class="yearsCircle"></div>
      <div class="-mt-[4.5rem] lg:-mt-[6rem] xl:-mt-[7.5rem] z-0">
        <p class="text-center text-2xl lg:text-3xl xl:text-4xl">
          +{{ statisticsNumbers[2].animatedValue | number : "1.0-0" }}
        </p>
        <p class="text-center lg:text-lg xl:text-xl">
          {{ "client" | transloco }}
        </p>
      </div>
    </div>
    <div class="grid justify-center">
      <div class="techCircle"></div>
      <div class="-mt-[4.5rem] lg:-mt-[6rem] xl:-mt-[7.5rem] z-0">
        <p class="text-center text-2xl lg:text-3xl xl:text-4xl">
          +{{ statisticsNumbers[3].animatedValue | number : "1.0-0" }}
        </p>
        <p class="text-center lg:text-lg xl:text-xl">
          {{ "technologies" | transloco }}
        </p>
      </div>
    </div>
  </div>

  <div
    class="bg-[#091627] md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto rounded-3xl py-5 lg:py-10 mt-36"
    [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
    id="aboutMe"
    #aboutMe
  >
    <div class="flex items-center justify-center">
      <p class="text-center font-semibold inline-block text-3xl">
        {{ "aboutMe" | transloco }}
        <svg
          class="mt-2"
          width="60"
          height="2"
          viewBox="0 0 87 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line y1="1" x2="87" y2="1" stroke="#49AEAC" stroke-width="2" />
        </svg>
      </p>
    </div>
    <div appFadeInUp class="flex items-center justify-center mt-8">
      <p
        class="mute text-sm px-2 lg:px-8"
        [ngClass]="activeLang == 'ar' ? 'text-right' : 'text-left'"
      >
        {{ expanded ? currentText : displayedText }}
        <span *ngIf="!showExpanded"> |</span>
        <span
          *ngIf="showExpanded"
          (click)="toggleExpand()"
          class="main_color hover:underline cursor-pointer"
        >
          {{
            expanded
              ? showLesseAccordingToActiveLang()
              : readMoreAccordingToActiveLang()
          }}
        </span>
      </p>
    </div>
  </div>

  <div class="w-full py-16 md:px-24 xl:px-32">
    <div class="container mx-auto px-4 py-24">
      <div
        appFadeInUp
        class="flex items-center justify-center"
        [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
      >
        <p class="text-center font-semibold inline-block text-3xl">
          {{ "mySkills" | transloco }}
          <svg
            class="mt-2"
            width="67"
            height="2"
            viewBox="0 0 87 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line y1="1" x2="87" y2="1" stroke="#49AEAC" stroke-width="2" />
          </svg>
        </p>
      </div>
      <p appFadeInUp class="text-[#B7B7B7] text-center pb-16 pt-3">
        {{ "workHard" | transloco }}
      </p>
      <div class="lg:flex lg:flex-wrap w-full">
        <div class="lg:w-1/2 lg:px-12">
          <!-- Angular -->
          <div class="flex items-center justify-between gap-2">
            <span class="text-lg lg:text-xl">Angular</span>
            <span class="text-xl font-bold"
              >{{ barsNumbers[0].animatedValue }} %</span
            >
          </div>
          <div class="w-full bg-gray-200 rounded-full overflow-hidden">
            <div
              class="h-2 rounded-full"
              id="angularAn"
              #angularAn
              style="width: 85%"
            ></div>
          </div>

          <!-- Node JS -->
          <div class="flex items-center justify-between gap-2 mt-5 lg:mt-10">
            <span class="text-lg lg:text-xl">NodeJS Express</span>
            <span class="text-xl font-bold"
              >{{ barsNumbers[1].animatedValue }} %</span
            >
          </div>
          <div class="w-full bg-gray-200 rounded-full overflow-hidden">
            <div
              class="h-2 rounded-full"
              id="NodeAn"
              #Node
              style="width: 78%"
            ></div>
          </div>

          <!-- MongoDB -->
          <div class="flex items-center justify-between gap-2 mt-5 lg:mt-10">
            <span class="text-lg lg:text-xl">MongoDB</span>
            <span class="text-xl font-bold"
              >{{ barsNumbers[2].animatedValue }} %</span
            >
          </div>
          <div class="w-full bg-gray-200 rounded-full overflow-hidden">
            <div
              class="h-2 rounded-full"
              id="MongoDBAn"
              #MongoDBAn
              style="width: 80%"
            ></div>
          </div>
        </div>

        <div class="lg:w-1/2 lg:px-12 mt-5 lg:mt-0">
          <!-- Javascript -->
          <div class="flex items-center justify-between gap-2">
            <span class="text-lg lg:text-xl">Javascript</span>
            <span class="text-xl font-bold"
              >{{ barsNumbers[3].animatedValue }} %</span
            >
          </div>
          <div class="w-full bg-gray-200 rounded-full overflow-hidden">
            <div
              class="h-2 rounded-full"
              id="JavascriptAn"
              #JavascriptAn
              style="width: 85%"
            ></div>
          </div>
          <!-- Ionic -->
          <div class="flex items-center justify-between gap-2 mt-5 lg:mt-10">
            <span class="text-xl">Ionic</span>
            <span class="text-xl font-bold"
              >{{ barsNumbers[4].animatedValue }} %</span
            >
          </div>
          <div class="w-full bg-gray-200 rounded-full overflow-hidden">
            <div
              class="h-2 rounded-full"
              id="IonicAn"
              #IonicAn
              style="width: 70%"
            ></div>
          </div>

          <!-- Unreal Engine -->
          <div class="flex items-center justify-between gap-2 mt-5 lg:mt-10">
            <span class="text-xl">Unreal Engine</span>
            <span class="text-xl font-bold"
              >{{ barsNumbers[5].animatedValue }} %</span
            >
          </div>
          <div class="w-full bg-gray-200 rounded-full overflow-hidden">
            <div
              class="h-2 rounded-full"
              id="unrealAn"
              #unrealAn
              style="width: 40%"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    appFadeInUp
    class="flex items-center justify-center"
    [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
  >
    <p class="text-center font-semibold inline-block text-3xl">
      {{ "education" | transloco }}
      <svg
        class="mt-2"
        width="67"
        height="2"
        viewBox="0 0 87 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="1" x2="87" y2="1" stroke="#49AEAC" stroke-width="2" />
      </svg>
    </p>
  </div>
  <div appFadeInUp [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'">
    <div class="flex items-center justify-center">
      <p
        class="mute w-full text-xs md:text-sm lg:text-lg px-2 lg:px-0 xl:w-2/3 text-center pb-16 pt-3"
      >
        {{ "acquired1" | transloco }}
        <br />
        {{ "acquired2" | transloco }}
      </p>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-3 xl:px-48">
      <div class="flex-wrap justify-center">
        <img
          class="mx-auto mb-3"
          src="assets\homePage\certificate1.svg"
          loading="lazy"
          width="10%"
          alt="certificate"
        />
        <p class="text-xs font-extrabold text-center uppercase">
          College Board
        </p>
        <p class="text-xs text-center mute pt-3">
          {{ "collegeBoard1" | transloco }}
          <br />

          {{ "collegeBoard2" | transloco }}
        </p>
      </div>
      <div class="flex-wrap justify-center my-12 md:my-0">
        <img
          class="mx-auto mb-3"
          src="assets\homePage\online-learning1.svg"
          loading="lazy"
          width="10%"
          alt="online-learning"
        />
        <p class="text-xs font-extrabold text-center uppercase">
          Hong Kong University
        </p>
        <p class="text-xs text-center mute pt-3">
          {{ "hongKong1" | transloco }}
          <br />

          {{ "hongKong2" | transloco }}
        </p>
      </div>
      <div class="flex-wrap justify-center">
        <img
          class="mx-auto mb-3"
          src="assets\homePage\univeristy1.svg"
          loading="lazy"
          width="10%"
          alt="univeristy"
        />
        <p class="text-xs font-extrabold text-center uppercase">
          harran university
        </p>
        <p class="text-xs text-center mute pt-3">
          {{ "harranUni1" | transloco }}
          <br />

          {{ "harranUni2" | transloco }}
        </p>
      </div>
    </div>
  </div>

  <div
    appFadeInUp
    class="flex items-center justify-center mt-20 md:mt-52"
    [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
  >
    <p class="text-center font-semibold inline-block text-3xl">
      {{ "whatClients" | transloco }}
      <svg
        class="mt-1"
        width="100"
        height="2"
        viewBox="0 0 100 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="1" x2="100" y2="1" stroke="#49AEAC" stroke-width="2" />
      </svg>
    </p>
  </div>

  <div
    appFadeInUp
    class="flex items-center justify-center"
    [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
  >
    <p
      class="mute text-xs w-11/12 md:text-sm lg:text-lg lg:w-2/3 px-2 lg:px-0 text-center pb-16 pt-3"
    >
      {{ "whatClients1" | transloco }}
      <br />

      {{ "whatClients2" | transloco }}
    </p>
  </div>

  <swiper-container
    [breakpoints]="breakpoints"
    loop="true"
    autoplay
    rewind="true"
    space-between="30"
    pagination="true"
    autoplay-delay="10000"
    speed="500"
  >
    <swiper-slide
      class="h-[510px] lg:h-[480px]"
      *ngFor="let clint of clientsW; trackBy: trackByFn"
    >
      <img
        class="mx-auto -mb-20 min-h-[130px]"
        [src]="clint.image.url"
        alt="Client_image"
      />
      <div class="bg-[#091627] rounded-lg min-h-[450px] pb-3">
        <div class="absolute mt-24">
          <P class="text-center font-extrabold">
            {{ clint.name[activeLang] }}
          </P>
          <P class="text-center mute text-sm">
            {{ clint.jobTitle[activeLang] }}
          </P>
          <img
            class="w-10 mx-auto my-4"
            src="assets\homePage\comma.svg"
            loading="lazy"
            alt="comma"
          />
          <p
            class="text-xs mute clients"
            [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
          >
            {{ clint.description[activeLang] }}
          </p>
        </div>
      </div>
    </swiper-slide>
  </swiper-container>

  <div
    appFadeInUp
    class="flex items-center justify-center mt-24 lg:mt-52"
    [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
  >
    <p class="text-center font-semibold inline-block text-3xl">
      {{ "trustedBy" | transloco }}
      <svg
        class="mt-1"
        width="50"
        height="2"
        viewBox="0 0 50 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="1" x2="50" y2="1" stroke="#49AEAC" stroke-width="2" />
      </svg>
    </p>
  </div>
  <div appFadeInUp class="flex items-center justify-center">
    <p class="mute w-11/12 lg:w-1/2 text-center pb-16 pt-3">
      {{ "trustedByDes" | transloco }}
    </p>
  </div>
  <div>
    <swiper-container
      [breakpoints]="logoBreakpoints"
      loop="true"
      navigation="true"
      autoplay
      rewind="true"
      speed="500"
      autoplay-disable-on-interaction="false"
    >
      <swiper-slide *ngFor="let logo of logos">
        <img
          [src]="logo.image.url"
          loading="lazy"
          class="w-28 lg:w-24 mx-auto"
          alt="Client"
        />
      </swiper-slide>
    </swiper-container>
  </div>
</div>
