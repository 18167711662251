import { Component, OnInit } from '@angular/core';
import { FormService } from 'app/services/formService.service';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.scss']
})
export class TestComponent implements OnInit {

  items: string[] = [];

  constructor(private arrayService: FormService) { }

  ngOnInit() {
    this.arrayService.tasks$.subscribe(items => {
      this.items = items;
    });

    this.findTwoSum([2, 7, 11, 15], 9);
  }

  findTwoSum(arr, target) {
    const numIndices = new Map();

    for (let i = 0; i < arr.length; i++) {
      const complement = target - arr[i];
      console.log(complement);

      if (numIndices.has(complement)) {
        console.log('Result: ', [numIndices.get(complement), i]);

        return [numIndices.get(complement), i];
      }
      numIndices.set(arr[i], i);
      console.log('numIndices', numIndices);
    }

    return null;
  }

  addItem() {
    const newItem = `Item ${Math.floor(Math.random() * 100)}`;
    this.arrayService.addItem(newItem);
  }

  deleteItem(item) {
    this.arrayService.deleteItem(item);
  }

}