import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ProcessHttpmsgService } from './process-httpmsg.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
    providedIn: 'root'
})
export class GeneralService {

    constructor(private http: HttpClient,
        private processHTTPMsgService: ProcessHttpmsgService) { }

    getItems(route: string): Observable<any> {
        return this.http.get<any>(environment.baseUrl + route)
            .pipe(catchError(this.processHTTPMsgService.handlError));
    }

    getItem(route: string, id: string): Observable<any> {
        return this.http.get<any>(environment.baseUrl + `${route}/` + id)
            .pipe(catchError(this.processHTTPMsgService.handlError));
    }

    addItem(route: string, data: any): Observable<any> {
        return this.http.post<any>(environment.baseUrl + route, data)
            .pipe(catchError(this.processHTTPMsgService.handlError));
    }

    editItem(route: string, id: string, data: any): Observable<any> {
        return this.http.put<any>(environment.baseUrl + `${route}/` + id, data)
            .pipe(catchError(this.processHTTPMsgService.handlError));
    }

    deleteItem(route: string, id: string): Observable<any> {
        return this.http.delete<any>(environment.baseUrl + `${route}/` + id)
            .pipe(catchError(this.processHTTPMsgService.handlError));
    }


}
