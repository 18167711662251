import { isPlatformBrowser } from '@angular/common';
import { Directive, ElementRef, Renderer2, OnInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';

@Directive({
  selector: '[appFadeInUp]'
})
export class FadeInUpDirective implements OnInit, OnDestroy {
  private observer?: IntersectionObserver;

  constructor(private element: ElementRef, private renderer: Renderer2, @Inject(PLATFORM_ID) private platformId: Object) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if ('IntersectionObserver' in window) {
        this.observer = new IntersectionObserver((entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              this.renderer.addClass(this.element.nativeElement, 'fade-in-up');
            }
          });
        });

        this.observer.observe(this.element.nativeElement);
      } else {
        this.renderer.addClass(this.element.nativeElement, 'fade-in-up');
      }
    }

  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }
}
