<div class="p-10 mt-32">
  <a (click)="addItem()"> Add Item To Array </a>

  <ul>
    <li class="my-20" *ngFor="let item of items">
      {{ item }}
      <a
        class="bg-red-700 text-white p-3 rounded-lg"
        (click)="deleteItem(item)"
      >
        Delete
      </a>
    </li>
  </ul>
</div>
