<div
  class="mt-14"
  [ngClass]="activeLang === 'ar' ? 'cairo' : 'roboto'"
>
  <div
    class="flex items-center justify-center"
    [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
  >
    <p class="text-center text-3xl pt-10 inline-block">
      {{ project.name[activeLang] }}
      <svg
        class="mt-3"
        width="87"
        height="2"
        viewBox="0 0 87 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="1" x2="87" y2="1" stroke="#49AEAC" stroke-width="2" />
      </svg>
    </p>
  </div>
  <p class="text-center text-sm md:text-base  xl:px-24 mute text-balance" [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'">
    {{ project.longDes[activeLang] }}
  </p>
  <div class="w-full lg:w-11/12 mt-16 center h-[600px] overflow-y-auto">
    <img
    [src]="project.fullPage"
    loading="eager"
    class="max-w-full"
  />
  </div>
  <section class="w-full py-32">
    <div
      class="w-full flex justify-center mx-auto px-4"
      [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
    >
      <p class="text-center inline-block text-2xl">
        {{ "technologiesUsed" | transloco }}
        <svg
          class="mt-3"
          width="87"
          height="2"
          viewBox="0 0 87 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line y1="1" x2="87" y2="1" stroke="#49AEAC" stroke-width="2" />
        </svg>
      </p>
    </div>
    <div class="w-full lg:w-2/3 xl:w-1/2 lg:mx-auto">
      <img
      class="center mt-4"
      src="assets\project\TUsedTailwind.svg"
      width="100%"
      alt=""
    />
    </div>

  </section>
  <div
    *ngIf="project.projectLink !== '#'"
    class="w-full lg:w-2/3 center text-center lg:mt-12"
  >
    <p class="text-lg xl:text-2xl">
      {{ "fullyPreview" | transloco }}
    </p>
    <a
      class="main_color text-1xl underline"
      [href]="project.projectLink"
      target="_blank"
    >
      {{ "clickHere" | transloco }}
    </a>
  </div>
</div>
