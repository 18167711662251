import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslocoService } from '@ngneat/transloco';
import { Project } from 'app/shared/dataType';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.scss']
})
export class ProjectsComponent implements OnInit, OnDestroy {
  projects$!: Observable<Project[]>;
  activeLang: string = 'ar';
  private langChangesSubscription!: Subscription;

  constructor(
    public router: ActivatedRoute,
    private _translocoService: TranslocoService,) { }

  ngOnInit(): void {
    this.getActiveLang()
    this.projects$ = this.router.data.pipe(map((data: any) => data.projects));

  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }
  trackByFn(index: number, item: any): any {
    return item._id || index;
  }


  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }

}
