import { Component, OnDestroy, OnInit } from '@angular/core';
import { Project } from '../shared/dataType';
import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { MetaDataSchemaService } from 'app/services/metadataschema.service';

@Component({
  selector: 'app-p-system',
  templateUrl: './p-system.component.html',
  styleUrls: ['./p-system.component.scss']
})
export class PSystemComponent implements OnInit, OnDestroy {
  index: number = 0;
  activeLang: string = 'ar';
  project: Project;
  private langChangesSubscription!: Subscription;

  constructor(
    public router: ActivatedRoute,
    private _translocoService: TranslocoService,
    private metaDataSchemaService: MetaDataSchemaService,
    private titleService: Title,
  ) { }

  ngOnInit(): void {
    this.router.data.subscribe((data) => {
      this.project = data.project;
    });
    this.getActiveLang();
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
      this.setMetaTags();
    });
  }

  isActive(index: number): boolean {
    return this.index === index;
  }

  setImageIndex(indexV: number) {
    this.index = indexV;
  }
  setMetaTags(): void {
    const allProjects = {
      website_angular_ssr: {
        titles: {
          en: 'Hazem | Angular SSR Development',
          ar: 'حازم | تطوير Angular SSR'
        },
        descriptions: {
          en: 'Developing high-performance server-side rendered Angular applications to enhance user experience and SEO.',
          ar: 'تطوير تطبيقات Angular عالية الأداء المعروضة من جانب الخادم لتحسين تجربة المستخدم وتحسين SEO.'
        },
        keywords: {
          en: 'Angular SSR, server-side rendering, web development, Angular applications, high-performance apps, SEO optimization',
          ar: 'Angular SSR, عرض من جانب الخادم, تطوير الويب, تطبيقات Angular, تطبيقات عالية الأداء, تحسين SEO'
        }
      },
      erp_system: {
        titles: {
          en: 'Hazem | ERP System Development',
          ar: 'حازم | تطوير نظام ERP'
        },
        descriptions: {
          en: 'Creating comprehensive ERP systems to manage all aspects of business operations effectively.',
          ar: 'إنشاء أنظمة ERP شاملة لإدارة جميع جوانب العمليات التجارية بفعالية.'
        },
        keywords: {
          en: 'ERP system, enterprise resource planning, business management, web development, custom ERP solutions',
          ar: 'نظام ERP, تخطيط موارد المؤسسات, إدارة الأعمال, تطوير الويب, حلول ERP مخصصة'
        }
      },
      process_automation: {
        titles: {
          en: 'Hazem | Administrative System Development',
          ar: 'حازم | تطوير نظام إداري'
        },
        descriptions: {
          en: 'Developing administrative systems for efficient business management and process automation.',
          ar: 'تطوير أنظمة إدارية لإدارة الأعمال بكفاءة وأتمتة العمليات.'
        },
        keywords: {
          en: 'administrative system, business management, process automation, web development, custom solutions',
          ar: 'نظام إداري, إدارة الأعمال, أتمتة العمليات, تطوير الويب, حلول مخصصة'
        }
      },
      universities_exams: {
        titles: {
          en: 'Hazem | Educational platform',
          ar: 'حازم | منصة تعليمية'
        },
        descriptions: {
          en: 'Developing educational platforms for exams and training lessons.',
          ar: 'تطوير منصات تعليمية لإجراء الامتحانات وحضور دروس تدريبية.'
        },
        keywords: {
          en: 'educational platform, education, exam, quiz, university, college',
          ar: 'منصة تعليمية, تعليم, امتحان, اختبار, جامعة, كلية'
        }
      }
    };

    const project = allProjects[this.project.seoURL];
    const lang = this.activeLang === 'ar' ? 'en' : 'ar';

    const schemaContent = {
      en: {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": project.titles['en'],
        "description": project.descriptions['en'],
        "provider": {
          "@type": "Organization",
          "name": "Hazem Dev"
        },
        "serviceType": "SoftwareApplication"
      },
      ar: {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": project.titles['ar'],
        "description": project.descriptions['ar'],
        "provider": {
          "@type": "Organization",
          "name": "حازم ديف"
        },
        "serviceType": "SoftwareApplication"
      }
    };
    this.titleService.setTitle(project.titles[this.activeLang]);
    this.metaDataSchemaService.updateTagsSchema(
      this.activeLang,
      `administrative_system/${this.project.seoURL}`,
      lang,
      project.descriptions,
      project.titles,
      project.keywords,
      schemaContent
    );
  }

  ngOnDestroy(): void {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }
}

