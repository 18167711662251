<div class="mt-28" [ngClass]="activeLang === 'ar' ? 'cairo' : 'roboto'">
  <div
    appFadeInUp
    class="flex items-center justify-center"
    [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
  >
    <p class="text-center font-semibold inline-block text-3xl">
      {{ "ourServices" | transloco }}
      <svg
        class="mt-1"
        width="50"
        height="2"
        viewBox="0 0 50 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="1" x2="50" y2="1" stroke="#49AEAC" stroke-width="2" />
      </svg>
    </p>
  </div>
  <div
    class="grid grid-cols-1 lg:grid-cols-3 gap-8 px-10 mt-10"
    [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
  >
    <div
      class="bg-gradient-to-r from-[#16182A] to-[#12141F] rounded-xl p-5 cursor-pointer transition-shadow duration-300 ease-in-out hover:shadow-slate-700 hover:shadow-lg"
    >
      <p class="font-bold">
        <i
          class="fa-regular fa-star text-xl"
          [ngClass]="activeLang === 'ar' ? 'ml-2' : 'mr-2'"
        ></i>

        {{ "websites" | transloco }}
      </p>
      <p class="text-[#B7B7B7]">
        {{ "websitesDes" | transloco }}
      </p>
    </div>
    <div
      class="bg-gradient-to-r from-[#16182A] to-[#12141F] rounded-xl p-5 cursor-pointer transition-shadow duration-300 ease-in-out hover:shadow-slate-700 hover:shadow-lg"
    >
      <p class="font-bold">
        <i
          class="fa-regular fa-lightbulb text-xl"
          [ngClass]="activeLang === 'ar' ? 'ml-2' : 'mr-2'"
        ></i>

        {{ "advices" | transloco }}
      </p>
      <p class="text-[#B7B7B7]">
        {{ "advicesDes" | transloco }}
      </p>
    </div>
    <div
      class="bg-gradient-to-r from-[#16182A] to-[#12141F] rounded-xl p-5 cursor-pointer transition-shadow duration-300 ease-in-out hover:shadow-slate-700 hover:shadow-lg"
    >
      <p class="font-bold">
        <i
          class="fa-solid fa-person-chalkboard text-xl"
          [ngClass]="activeLang === 'ar' ? 'ml-2' : 'mr-2'"
        ></i>

        {{ "onlineCourses" | transloco }}
      </p>
      <p class="text-[#B7B7B7]">
        {{ "onlineCoursesDes" | transloco }}
      </p>
    </div>
  </div>
</div>
