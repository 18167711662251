<div class="flex flex-col mt-28">
  <div class="w-full bg-[#0E111E] rounded-t-3xl">
    <div class="pt-16 pb-5 px-10 xl:px-40">
      <svg
        width="100%"
        height="3"
        viewBox="0 0 1920 3"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          y1="1.5"
          x2="100%"
          y2="1.5"
          stroke="url(#paint0_linear_22_145)"
          stroke-width="3"
        />
        <defs>
          <linearGradient
            id="paint0_linear_22_145"
            x1="0"
            y1="3"
            x2="100%"
            y2="3"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#49AEAC" />
            <stop offset="0.52" stop-color="white" />
            <stop offset="1" stop-color="#49AEAC" />
          </linearGradient>
        </defs>
      </svg>
      <div class="md:flex items-center my-8">
        <div class="w-full md:w-6/12">
          <p class="text-5xl text-center sm:text-left">Get in Touch</p>
          <p class="text-center sm:text-left w-full mute roboto pt-4">
            Connect with me on social media to stay updated on my latest
            projects and insights
          </p>
          <div
            class="flex items-center justify-center md:justify-start gap-4 mt-5"
          >
            <!-- WhatsApp -->
            <a
              class="cursor-pointer"
              matTooltip="WhatsApp"
              target="_blank"
              href="https://wa.me/+971557827461"
              aria-label="WhatsApp"
            >
              <img
                class="w-10"
                src="assets/socialMedia/whatsapp.svg"
                alt="WhatsApp"
              />
            </a>
            <!-- Instagram -->
            <a
              class="cursor-pointer"
              matTooltip="Instagram"
              href="https://www.instagram.com/alsweadd?igsh=NXdibnFwdjB4ajRq&utm_source=qr"
              target="_blank"
              aria-label="Instagram"
            >
              <img
                class="w-10"
                src="assets/socialMedia/inistagram.svg"
                alt="Instagram"
              />
            </a>
            <!-- Facebook -->
            <a
              class="cursor-pointer"
              matTooltip="Facebook"
              href="https://www.facebook.com/share/La1kW54LKCrBoqG9/?mibextid=LQQJ4d"
              target="_blank"
              aria-label="Facebook"
            >
              <img
                class="w-10"
                src="assets/socialMedia/facebook.svg"
                alt="Facebook"
              />
            </a>
            <!-- LinkedIn -->
            <a
              class="cursor-pointer"
              matTooltip="LinkedIn"
              href="https://www.linkedin.com/in/hazem-sweed-758bbb231/"
              target="_blank"
              aria-label="LinkedIn"
            >
              <img
                class="w-10"
                src="assets/socialMedia/linkedin.svg"
                alt="LinkedIn"
              />
            </a>
            <!-- Telegram -->
            <a
              class="cursor-pointer"
              matTooltip="Telegram"
              href="https://t.me/+905394708218"
              target="_blank"
              aria-label="Telegram"
            >
              <img
                class="w-10"
                src="assets/socialMedia/telegram.svg"
                alt="Telegram"
              />
            </a>
          </div>
        </div>
        <svg
          class="block md:hidden my-10"
          width="100%"
          height="3"
          viewBox="0 0 1920 3"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line
            y1="1.5"
            x2="100%"
            y2="1.5"
            stroke="url(#paint0_linear_22_145)"
            stroke-width="3"
          />
          <defs>
            <linearGradient
              id="paint0_linear_22_145"
              x1="0"
              y1="3"
              x2="100%"
              y2="3"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="#49AEAC" />
              <stop offset="0.52" stop-color="white" />
              <stop offset="1" stop-color="#49AEAC" />
            </linearGradient>
          </defs>
        </svg>
        <div class="hidden md:block w-1/12 lg:w-2/12">
          <svg
            class="center"
            width="3"
            height="200"
            viewBox="0 0 3 237"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1.50002"
              y1="9.73965e-08"
              x2="1.5"
              y2="237"
              stroke="url(#paint0_linear_22_136)"
              stroke-width="3"
            />
            <defs>
              <linearGradient
                id="paint0_linear_22_136"
                x1="-0.000517181"
                y1="237"
                x2="1.51758e-05"
                y2="0"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="white" />
                <stop offset="1" stop-color="#49AEAC" />
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div class="w-full md:w-6/12 text-center">
          <p class="text-4xl font-semibold md:pt-5">HAZIM SWEED</p>
          <p class="mute pt-1">Full stack web developer</p>
          <svg
            class="center mt-5"
            width="132"
            height="3"
            viewBox="0 0 132 3"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="1.5"
              x2="132"
              y2="1.5"
              stroke="url(#paint0_linear_22_155)"
              stroke-width="3"
            />
            <defs>
              <linearGradient
                id="paint0_linear_22_155"
                x1="0"
                y1="3"
                x2="132"
                y2="3"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#49AEAC" />
                <stop offset="0.52" stop-color="white" />
                <stop offset="1" stop-color="#49AEAC" />
              </linearGradient>
            </defs>
          </svg>
          <div class="grid">
            <a class="pt-5" href="tel:+971557827461" aria-label="Phone Number">
              <span class="main_color">Tel: </span> +971 55 782 7461
            </a>

            <a
              class="pt-2"
              href="mailto:hazem.swees33a@gmail.com"
              aria-label="Email"
            >
              <span class="main_color">Email: </span>
              hazem.swees33a&#64;gmail.com
            </a>
          </div>
        </div>
      </div>
      <svg
        width="100%"
        height="3"
        viewBox="0 0 1920 3"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          y1="1.5"
          x2="100%"
          y2="1.5"
          stroke="url(#paint0_linear_22_145)"
          stroke-width="3"
        />
        <defs>
          <linearGradient
            id="paint0_linear_22_145"
            x1="0"
            y1="3"
            x2="100%"
            y2="3"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#49AEAC" />
            <stop offset="0.52" stop-color="white" />
            <stop offset="1" stop-color="#49AEAC" />
          </linearGradient>
        </defs>
      </svg>
      <div class="w-full md:flex md:items-center md:justify-between mt-3">
        <div class="md:w-1/3 text-center md:text-left">
          Developed By: <span class="main_color">Me</span>
        </div>
        <div class="md:w-1/3 text-center">
          Terms <span class="main_color">&</span> Conditions
        </div>
        <div class="md:w-1/3 text-center md:text-right">
          © {{ currentYear }} All Rights Reserved
        </div>
      </div>
    </div>
  </div>
</div>
