import { Component } from '@angular/core';

@Component({
  selector: 'app-fab-whats-app-icon',
  templateUrl: './fab-whats-app-icon.component.html',
  styleUrls: ['./fab-whats-app-icon.component.scss']
})
export class FabWhatsAppIconComponent {

}
