import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from '../shared/dataType';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-p-hisabionline',
  templateUrl: './p-hisabionline.component.html',
  styleUrls: ['./p-hisabionline.component.scss']
})
export class PHisabionlineComponent implements OnInit, OnDestroy {
  index: number = 0;
  activeLang: string = 'ar';
  project: Project;
  private langChangesSubscription!: Subscription;

  constructor(
    public router: ActivatedRoute,
    private _translocoService: TranslocoService,) { }

  ngOnInit(): void {
    this.router.data.subscribe((data) => {
      this.project = data.project;
    });
    this.getActiveLang();
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }


  isActive(index: number): boolean {
    return this.index === index;
  }

  setImageIndex(indexV: number) {
    this.index = indexV;
  }

  ngOnDestroy(): void {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }
}
