<div class="mt-28" [ngClass]="activeLang === 'ar' ? 'cairo' : 'roboto'">
  <div
    class="flex items-center justify-center mt-10"
    [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
  >
    <p
      appFadeInUp
      class="text-center font-semibold inline-block text-base lg:text-3xl"
    >
      {{ "letsMake" | transloco }}
      <svg
        class="mt-1"
        width="200"
        height="2"
        viewBox="0 0 200 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="1" x2="200" y2="1" stroke="#49AEAC" stroke-width="2" />
      </svg>
    </p>
  </div>
  <!-- Social Media Icons with Hover Animations -->
  <div class="flex justify-center space-x-16 mt-10">
    <a
      href="https://wa.me/+971557827461"
      target="_blank"
      class="text-white hover:text-[#49aeac] text-4xl transform hover:-translate-y-2 transition-all duration-300"
      matTooltip="WhatsApp"
    >
      <i class="fab fa-whatsapp"></i>
    </a>
    <a
      href="https://www.instagram.com/alsweadd?igsh=NXdibnFwdjB4ajRq&utm_source=qr"
      target="_blank"
      class="text-white hover:text-[#49aeac] text-4xl transform hover:-translate-y-2 transition-all duration-300"
      matTooltip="Instagram"
    >
      <i class="fab fa-instagram"></i>
    </a>
    <a
      href="https://www.facebook.com/share/La1kW54LKCrBoqG9/?mibextid=LQQJ4d"
      target="_blank"
      class="text-white hover:text-[#49aeac] text-4xl transform hover:-translate-y-2 transition-all duration-300"
      matTooltip="Facebook"
    >
      <i class="fab fa-facebook-f"></i>
    </a>
    <a
      href="https://www.linkedin.com/in/hazem-sweed-758bbb231/"
      target="_blank"
      class="text-white hover:text-[#49aeac] text-4xl transform hover:-translate-y-2 transition-all duration-300"
      matTooltip="LinkedIn"
    >
      <i class="fab fa-linkedin"></i>
    </a>
    <a
      href="https://t.me/+905394708218"
      target="_blank"
      class="text-white hover:text-[#49aeac] text-4xl transform hover:-translate-y-2 transition-all duration-300"
      matTooltip="Telegram"
    >
      <i class="fab fa-telegram-plane"></i>
    </a>
  </div>

  <div
    class="flex flex-col-reverse lg:grid grid-cols-1 lg:grid-cols-2 gap-5 mt-10 lg:mt-10 px-4 xl:px-64"
  >
    <form
      class="w-full lg:w-11/12 mt-10 lg:mt-0"
      autocomplete="off"
      [formGroup]="contactForm"
      (ngSubmit)="onSubmit()"
      [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
    >
      <p
        class="text-base"
        [ngClass]="activeLang === 'ar' ? 'text-right' : 'text-left'"
      >
        {{ "haveAnyQuestion" | transloco }}
      </p>
      <div class="form-group">
        <label class="text-[#B7B7B7]" for="fullName">
          {{ "fullName" | transloco }}
        </label>
        <input
          class="mt-3"
          type="text"
          formControlName="fullName"
          id="fullName"
          name="fullName"
          required
        />
        <mat-error *ngIf="formErrors.fullName">{{
          formErrors.fullName
        }}</mat-error>
      </div>
      <div class="form-group mt-6">
        <label class="text-[#B7B7B7]" for="email">
          {{ "email" | transloco }}
        </label>
        <input
          class="mt-3"
          type="email"
          formControlName="email"
          id="email"
          name="email"
          required
        />
        <mat-error *ngIf="formErrors.email">{{ formErrors.email }}</mat-error>
      </div>
      <div class="form-group mt-6">
        <label class="text-[#B7B7B7]" for="message">
          {{ "message" | transloco }}
        </label>
        <textarea
          class="mt-3"
          id="message"
          formControlName="message"
          name="message"
          rows="4"
          required
        ></textarea>
        <mat-error *ngIf="formErrors.message">{{
          formErrors.message
        }}</mat-error>
      </div>
      <!-- Success or Error Message -->
      <p *ngIf="submissionMessage" class="mt-5 text-center text-green-600">
        {{ submissionMessage }}
      </p>
      <!-- Submit Button with Spinner -->
      <button
        type="submit"
        [disabled]="contactForm.invalid || isSubmitting"
        class="w-full bg-[#49aeac] text-white font-bold py-4 rounded-lg mt-5 shadow-md hover:bg-[#398685] disabled:bg-gray-400 disabled:cursor-not-allowed transition-all duration-300 transform hover:shadow-lg"
      >
        <span *ngIf="!isSubmitting">{{ "sendMessage" | transloco }}</span>
        <span *ngIf="isSubmitting">
          <i class="fas fa-spinner fa-spin"></i>
        </span>
      </button>
    </form>

    <div
      class="bg-[#0F111D] rounded-3xl border-4 border-[#16182A] p-3"
      [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
    >
      <div class="bg-gradient-to-r from-[#16182A] to-[#12141F] rounded-xl p-5">
        <p class="font-bold">
          <i
            class="fa-solid fa-code text-xl"
            [ngClass]="activeLang === 'ar' ? 'ml-2' : 'mr-2'"
          ></i>
          {{ "customizeSoft" | transloco }}
        </p>
        <p class="text-[#B7B7B7]">
          {{ "weCreate" | transloco }}
        </p>
      </div>
      <div
        class="bg-gradient-to-r from-[#16182A] to-[#12141F] rounded-xl p-5 mt-4"
      >
        <p class="font-bold">
          <i
            class="fa-regular fa-lightbulb text-xl"
            [ngClass]="activeLang === 'ar' ? 'ml-2' : 'mr-2'"
          ></i>
          {{ "expertise" | transloco }}
        </p>
        <p class="text-[#B7B7B7]">
          {{ "ourTeam" | transloco }}
        </p>
      </div>
      <div
        class="bg-gradient-to-r from-[#16182A] to-[#12141F] rounded-xl p-5 mt-4"
      >
        <p class="font-bold">
          <i
            class="fa-regular fa-life-ring text-xl"
            [ngClass]="activeLang === 'ar' ? 'ml-2' : 'mr-2'"
          ></i>
          {{ "support" | transloco }}
        </p>
        <p class="text-[#B7B7B7]">
          {{ "weDontStop" | transloco }}
        </p>
      </div>
    </div>
  </div>
</div>
