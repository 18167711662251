<div
  class="mt-14"
  [ngClass]="activeLang === 'ar' ? 'cairo' : 'roboto'"
 
>
  <div class="flex items-center justify-center">
    <p
      class="text-center pt-10 text-2xl"
      [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
    >
      {{ project.name[activeLang] }}
      <svg
        class="mt-1"
        width="60"
        height="2"
        viewBox="0 0 60 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="1" x2="60" y2="1" stroke="#49AEAC" stroke-width="2" />
      </svg>
    </p>
  </div>
  <p class="text-center text-base px-2 sm:px-16 xl:px-40 mute pt-3" [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'">
    {{ project.longDes[activeLang] }}
  </p>
  <svg
    class="my-16"
    width="100%"
    height="3"
    viewBox="0 0 1230 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="1.5"
      x2="1230"
      y2="1.5"
      stroke="url(#paint0_linear_22_145)"
      stroke-width="3"
    />
    <defs>
      <linearGradient
        id="paint0_linear_22_145"
        x1="0"
        y1="3"
        x2="1230"
        y2="3"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#49AEAC" />
        <stop offset="0.52" stop-color="white" />
        <stop offset="1" stop-color="#49AEAC" />
      </linearGradient>
    </defs>
  </svg>
  <div class="grid items-center justify-center pb-6 px-2 sm:px-12 xl:px-32">
    <p class="text-center text-2xl">
      {{ "someMainTitles" | transloco }}
    </p>
  </div>
  <div
    class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4 px-2 sm:px-14 xl:px-32"
    [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
  >
    <div
      *ngFor="let feature of project.properties"
      class="p-4 bg-[#091627] text-white rounded-3xl shadow-md"
    >
      <div
        class="flex items-center justify-center bg-[#49AEAC] rounded-full w-10 h-10 text-[#020617] text-lg mb-4"
      >
        <i [class]="feature.icon"></i>
      </div>
      <p class="text-xl font-semibold mb-2">{{ feature.title[activeLang] }}</p>
      <p class="mute">{{ feature.description[activeLang] }}</p>
    </div>
  </div>
  <svg
    class="my-20"
    width="100%"
    height="3"
    viewBox="0 0 1230 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="1.5"
      x2="1230"
      y2="1.5"
      stroke="url(#paint0_linear_22_145)"
      stroke-width="3"
    />
    <defs>
      <linearGradient
        id="paint0_linear_22_145"
        x1="0"
        y1="3"
        x2="1230"
        y2="3"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#49AEAC" />
        <stop offset="0.52" stop-color="white" />
        <stop offset="1" stop-color="#49AEAC" />
      </linearGradient>
    </defs>
  </svg>
  <div *ngIf="project.projectImages && project.projectImages.length > 0">
    <div class="flex items-center justify-center">
      <h1 class="text-center inline-block">
        {{ "someSnapshots" | transloco }}
        <svg
          class="mt-1"
          width="150"
          height="2"
          viewBox="0 0 150 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line y1="1" x2="150" y2="1" stroke="#49AEAC" stroke-width="2" />
        </svg>
      </h1>
    </div>

    <div class=" mt-4">

        <img
          *ngIf="project.projectImages && project.projectImages[index]"
          class="w-full md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto"
          [src]="project.projectImages[index].url"
          loading="lazy"
          alt="Image"
        />
    </div>
    <div class="flex items-center justify-evenly mt-10 px-2 md:px-14">
      <a
        *ngFor="let image of project.projectImages; let i = index"
        (click)="setImageIndex(i)"
        [matTooltip]="image.name[activeLang]"
      >
        <img
          class="w-2/3 md:w-2/3 mx-auto"
          [src]="image.url"
          [class.mask]="!isActive(i)"
          loading="lazy"
          alt="Image"
        />
      </a>
    </div>
    <svg
      class="my-16"
      width="100%"
      height="3"
      viewBox="0 0 1230 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <line
        y1="1.5"
        x2="1230"
        y2="1.5"
        stroke="url(#paint0_linear_22_145)"
        stroke-width="3"
      />
      <defs>
        <linearGradient
          id="paint0_linear_22_145"
          x1="0"
          y1="3"
          x2="1230"
          y2="3"
          gradientUnits="userSpaceOnUse"
        >
          <stop stop-color="#49AEAC" />
          <stop offset="0.52" stop-color="white" />
          <stop offset="1" stop-color="#49AEAC" />
        </linearGradient>
      </defs>
    </svg>
  </div>

  <section class="w-full sm:w-10/12 lg:w-7/12 xl:w-1/2 mx-auto">
    <div
      class="w-full flex justify-center mx-auto px-4"
      [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
    >
      <p class="text-center inline-block text-2xl">
        {{ "technologiesUsed" | transloco }}
        <svg
          class="mt-3"
          width="87"
          height="2"
          viewBox="0 0 87 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line y1="1" x2="87" y2="1" stroke="#49AEAC" stroke-width="2" />
        </svg>
      </p>
    </div>
    <div
      *ngIf="
        project.name['en'] === 'Noontek Erp' || project.name['en'] === 'Yosbook'
      "
    >
      <img
        class="center mt-4"
        src="assets\project\noontekTech.svg"
        width="100%"
        loading="lazy"
        alt="Image"
      />
    </div>
    <div *ngIf="project.name['en'] === 'Seneteam System'">
      <img
        class="center mt-4"
        src="assets\project\TUsedMDB.svg"
        width="100%"
        loading="lazy"
        alt="Image"
      />
    </div>
    <div *ngIf="project.name['en'] === 'Zirve Packaging'">
      <img
        class="center mt-4"
        src="assets\project\zirve.svg"
        width="100%"
        loading="lazy"
        alt="Image"
      />
    </div>
  </section>
  <div
    *ngIf="project.projectLink !== '#'"
    class="w-full lg:w-2/3 center text-center mt-12"
  >
    <p class="text-sm lg:text-2xl">
      {{ "fullyPreview" | transloco }}
    </p>
    <a
      class="main_color text-1xl underline"
      [href]="project.projectLink"
      target="_blank"
    >
      {{ "clickHere" | transloco }}
    </a>
  </div>
</div>
