import { Component, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';
import { GeneralService } from '../services/general.service';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})

export class HomeComponent implements OnInit, OnDestroy {
  logos: any[] = [];
  clientsW: any[] = [];
  activeLang: string = 'ar';
  private langChangesSubscription!: Subscription;
  statisticsNumbers: { value: number, animatedValue: number, increment: number }[] = [
    { value: 7, animatedValue: 0, increment: 0 },
    { value: 31, animatedValue: 0, increment: 0 },
    { value: 17, animatedValue: 0, increment: 0 },
    { value: 12, animatedValue: 0, increment: 0 }
  ];
  barsNumbers: { value: number, animatedValue: number }[] = [
    { value: 85, animatedValue: 0 },
    { value: 78, animatedValue: 0 },
    { value: 80, animatedValue: 0 },
    { value: 85, animatedValue: 0 },
    { value: 70, animatedValue: 0 },
    { value: 40, animatedValue: 0 }
  ];
  breakpoints = {
    300: {
      slidesPerView: 1,
    },
    768: {
      slidesPerView: 2,
    },
    1400: {
      slidesPerView: 3,
    },
  };
  logoBreakpoints = {
    300: {
      slidesPerView: 2.5,
    },
    768: {
      slidesPerView: 4,
    },
    1024: {
      slidesPerView: 5,
    },
    1720: {
      slidesPerView: 7,
    },
  };
  statisticsAnimateStarted = false;
  barsAnimateStarted = false;
  screenWidth: number = 0;
  text: string;
  displayedText: string;
  expanded: boolean = false;
  currentText: string = '';
  typingInterval: any;
  initialDisplayText: string;
  audio: HTMLAudioElement;
  showExpanded: boolean = true;

  constructor(
    private _translocoService: TranslocoService,
    private gs: GeneralService, @Inject(PLATFORM_ID)
    private platformId: object,) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getActiveLang();
      this.getClientsWords();
      this.getClientsLogos();
      this.audio = new Audio('assets/sounds/keyboard.mp3');
      this._translocoService.selectTranslate('aboutMeDes').subscribe((translation: string) => {
        this.text = translation;
        this.initialDisplayText = this.text.split(' ').slice(0, 150).join(' ');
        this.displayedText = this.initialDisplayText;
        this.currentText = this.initialDisplayText;
      });
      this.screenWidth = window.innerWidth;
    }
  }

  toggleExpand(): void {
    if (!this.expanded) {
      this.startTyping();
    } else {
      this.currentText = this.initialDisplayText;
    }
    this.expanded = !this.expanded;
  }

  startTyping(): void {
    this.showExpanded = false;
    const initialLength = this.initialDisplayText.length; // -3 to exclude the '...'
    const remainingText = this.text.slice(initialLength);
    let currentLength = 0;
    this.audio.loop = true;
    this.audio.play();
    this.typingInterval = setInterval(() => {
      if (currentLength < remainingText.length) {
        this.currentText += remainingText.charAt(currentLength);
        currentLength++;
      } else {
        clearInterval(this.typingInterval);
        this.audio.pause();
        this.audio.currentTime = 0; // Reset audio for next play
        this.showExpanded = true;
      }
    }, 10); // Adjust typing speed by changing the interval time
  }

  readMoreAccordingToActiveLang() {
    let value;
    if (this.activeLang === 'ar') {
      value = 'أكمل القراءة...';
    } else {
      value = 'Complete Reading...';
    }
    return value;
  }

  showLesseAccordingToActiveLang() {
    let value;
    if (this.activeLang === 'ar') {
      value = 'إخفاء المحتوى';
    } else {
      value = 'Show less';
    }
    return value;
  }

  getClientsWords() {
    this.gs.getItems('clients').subscribe((res) => {
      this.clientsW = res;
    })
  }

  getClientsLogos() {
    this.gs.getItems('clientsLogo').subscribe((res) => {
      this.logos = res;

    })
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  @HostListener('window:scroll', [])
  onScroll() {
    if (this.statisticsAnimateStarted && this.barsAnimateStarted) {
      return;
    }
    const scrollPosition = window.scrollY;
    if (window.innerWidth > 500 && window.innerWidth < 1100) {
      if (scrollPosition >= 100 && !this.statisticsAnimateStarted) {
        this.statisticsAnimateStarted = true;
        this.animateStatisticsNumbers();
      }
      if (scrollPosition >= 800 && !this.barsAnimateStarted) {
        this.barsAnimateStarted = true;
        this.statrtFillAniFun();
        this.animateFillNumbers();
      }
    } else {
      if (scrollPosition >= 300 && !this.statisticsAnimateStarted) {
        this.statisticsAnimateStarted = true;
        this.animateStatisticsNumbers();
      }
      if (scrollPosition >= 1250 && !this.barsAnimateStarted) {
        this.barsAnimateStarted = true;
        this.statrtFillAniFun();
        this.animateFillNumbers();
      }
    }

  }

  statrtFillAniFun() {
    const angularElement = document.getElementById('angularAn');
    angularElement.classList.add('Angularfill-bar');

    const javascriptElement = document.getElementById('NodeAn');
    javascriptElement.classList.add('Nodefill-bar');

    const nodejsElement = document.getElementById('MongoDBAn');
    nodejsElement.classList.add('MongoDBfill-bar');

    const ionicElement = document.getElementById('JavascriptAn');
    ionicElement.classList.add('Javascriptfill-bar');

    const mongoElement = document.getElementById('IonicAn');
    mongoElement.classList.add('Ionicfill-bar');

    const unrealElement = document.getElementById('unrealAn');
    unrealElement.classList.add('Unrealfill-bar');

  }


  animateFillNumbers() {
    let targetValuesReached = false;
    const interval = setInterval(() => {
      if (targetValuesReached) {
        clearInterval(interval);
        return;
      }
      targetValuesReached = true;
      this.barsNumbers.forEach((number, index) => {
        const targetValue = number.value;
        let currentValue = number.animatedValue;
        if (currentValue < targetValue) {
          targetValuesReached = false;
          currentValue++;
          this.barsNumbers[index].animatedValue = currentValue;
        }
      });
    }, 11); // Adjust the interval for smoother animation
  }

  animateStatisticsNumbers() {
    const totalDuration = 2000; // Total animation duration in milliseconds
    const steps = 100; // Number of animation steps
    const intervalDuration = totalDuration / steps;
    this.statisticsNumbers.forEach(number => {
      number.increment = number.value / steps;
    });
    let currentStep = 0;
    const interval = setInterval(() => {
      currentStep++;
      if (currentStep > steps) {
        clearInterval(interval);
        // Ensure the final values are exactly the target values
        this.statisticsNumbers.forEach(number => {
          number.animatedValue = number.value;
        });
        return;
      }
      this.statisticsNumbers.forEach(number => {
        const remaining = number.value - number.animatedValue;
        if (currentStep === steps) {
          number.animatedValue += remaining;
        } else {
          number.animatedValue = Math.min(number.value, number.animatedValue + number.increment);
        }
      });
    }, intervalDuration);
  }

  trackByFn(index: number, item: any): any {
    return item._id || index;
  }

  getElToViewPortFun(itemId) {
    const targetElement = document.getElementById(itemId);
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
    }
  }




  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }
}
