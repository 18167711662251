import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {
    constructor(private router: Router) { }

    setLanguage(lang: string) {
        // Save the language to localStorage
        localStorage.setItem('usedLang', lang);

        // Get the current URL and update the language in the URL if necessary
        const currentUrl = this.router.url;
        const updatedUrl = currentUrl.replace(/\/[a-zA-Z]{2}\//, `/${lang}/`);

        if (currentUrl !== updatedUrl) {
            // Navigate to the updated URL
            this.router.navigateByUrl(updatedUrl, { replaceUrl: true });
        }
    }
}
