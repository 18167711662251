import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from '../shared/dataType';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})

export class ProjectComponent implements OnInit, OnDestroy {
  activeLang: string = 'ar';
  project: Project;
  private langChangesSubscription!: Subscription;

  constructor(
    private sanitizer: DomSanitizer,
    public router: ActivatedRoute,
    private _translocoService: TranslocoService,
  ) { }

  ngOnInit(): void {
    this.router.data.subscribe((data) => {
      this.project = data.project;
    });
    this.getActiveLang();
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnDestroy(): void {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }

}
