import { NgModule } from '@angular/core';
import { ExtraOptions, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ProjectComponent } from './project/project.component';
import { ProjectsComponent } from './projects/projects.component';
import { PHisabionlineComponent } from './p-hisabionline/p-hisabionline.component';
import { PSystemComponent } from './p-system/p-system.component';
import { ProjectResolver, ProjectsResolver } from './resolvers/project.resolver';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogComponent } from './blog/blog.component';
import { BlogResolver, BlogsResolver } from './resolvers/news_blogs.resolver';
import { ContactComponent } from './contact/contact.component';
import { OurServicesComponent } from './our-services/our-services.component';
import { MetaDatgsResolver } from './resolvers/metaData.resolver';
import { TestComponent } from './test/test.component';

const routes: Routes = [
  { path: "", redirectTo: "ar/home", pathMatch: "full" },
  { path: ":lang/home", component: HomeComponent, resolve: { meta: MetaDatgsResolver }, data: { pageName: 'home' } },
  { path: ":lang/contact", component: ContactComponent, resolve: { meta: MetaDatgsResolver }, data: { pageName: 'contact' } },
  { path: ":lang/services", component: OurServicesComponent, resolve: { meta: MetaDatgsResolver }, data: { pageName: 'services' } },
  { path: ":lang/projects", component: ProjectsComponent, resolve: { projects: ProjectsResolver } },
  { path: ":lang/stores/:id", component: ProjectComponent, resolve: { project: ProjectResolver }, data: { pageName: 'stores' } },
  { path: ":lang/accounting_program/:id", component: PHisabionlineComponent, resolve: { project: ProjectResolver }, data: { pageName: 'accounting_program' } },
  { path: ":lang/administrative_system/:id", component: PSystemComponent, resolve: { project: ProjectResolver }, data: { pageName: 'administrative_system' } },
  { path: ":lang/blogs", component: BlogsComponent, resolve: { blogs: BlogsResolver }, },
  { path: ":lang/blog/:id", component: BlogComponent, resolve: { blog: BlogResolver }, },
  { path: ":lang/test", component: TestComponent, },

  { path: "**", redirectTo: "ar/home" },
];

const routerConfig: ExtraOptions = {
  preloadingStrategy: PreloadAllModules,
  scrollPositionRestoration: 'enabled',
  initialNavigation: 'enabledBlocking'
};

@NgModule({
  imports: [RouterModule.forRoot(routes, routerConfig)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
