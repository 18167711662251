import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable } from 'rxjs';
import { GeneralService } from '../services/general.service';
import { Project } from '../shared/dataType';
import { LoadingService } from 'app/services/Loading.service';
import { MetaDataSchemaService } from 'app/services/metadataschema.service';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})


@Injectable({ providedIn: 'root' })
export class ProjectsResolver implements Resolve<Project[]> {
    constructor(private generalService: GeneralService, private metaDataSchemaService: MetaDataSchemaService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Project[]> {
        const lang = route.params['lang'];
        this.metaDataSchemaService.setProjectsMetaTags(lang);
        return this.generalService.getItems('project'); // Send as query parameter
    }
}

@Injectable({ providedIn: 'root' })
export class ProjectResolver implements Resolve<Project> {
    constructor(private generalService: GeneralService, private loadingService: LoadingService, private metaDataSchemaService: MetaDataSchemaService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<Project> {
        this.loadingService.show();
        const id = route.params['id'];
        const lang = route.params['lang'];
        const page = route.data['pageName'];
        return this.generalService.getItem("project", id).pipe(
            map((project: any) => {
                if (page === 'stores') {
                    this.metaDataSchemaService.setOnlineStoreMetaTags(lang, project.seoURL);
                } else if (page === 'administrative_system') {
                    this.metaDataSchemaService.setAdminstrativeSystemMetaTags(lang, project.seoURL);
                } else {
                    this.metaDataSchemaService.setAccountingProgramMetaTags(lang);
                } 
                this.loadingService.hide();
                return project as Project;
            })
          ); 
    }
}








