<div [ngClass]="activeLang === 'ar' ? 'cairo' : 'roboto'">
  <div appFadeInUp class="flex items-center justify-center mt-24 lg:mt-32">
    <p
      class="text-center font-semibold inline-block text-3xl"
      [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
    >
      {{ "myProjects" | transloco }}
      <svg
        class="mt-1"
        width="90"
        height="2"
        viewBox="0 0 87 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="1" x2="87" y2="1" stroke="#49AEAC" stroke-width="2" />
      </svg>
    </p>
  </div>
  <div appFadeInUp class="flex items-center justify-center pt-5">
    <p class="w-11/12 lg:w-7/12 text-[#B7B7B7] text-center">
      {{ "myProjectsDes" | transloco }}
    </p>
  </div>

  <div
    class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 xl:gap-14 px-10 xl:px-24 mt-20"
  >
    <div
      *ngFor="let project of projects$ | async; trackBy: trackByFn"
      class="bg-[#091627] text-white rounded-t-3xl shadow-md"
    >
      <a
        class=""
        [routerLink]="['/', activeLang, project.router, project.seoURL]"
      >
        <img
          class="rounded-t-3xl min-h-[200px]"
          [src]="project.imgUrl"
          width="100%"
          alt="Image"
          loading="lazy"
        />
        <p class="text-xl font-semibold text-center pt-4">
          {{ project.name[activeLang] }}
        </p>
        <svg
          class="my-3 mx-auto"
          width="87"
          height="2"
          viewBox="0 0 87 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line y1="1" x2="87" y2="1" stroke="#49AEAC" stroke-width="2" />
        </svg>
        <div class="flex items-start justify-center pb-8">
          <p class="text-xs text-center w-5/6">{{ project.des[activeLang] }}</p>
        </div>
      </a>
    </div>
  </div>
</div>
