import { NgModule, CUSTOM_ELEMENTS_SCHEMA, } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms';

import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatMenuModule } from '@angular/material/menu';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSidenavModule } from "@angular/material/sidenav";

import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DeferLoadDirective } from './directives/defer-load.directive';
import { FadeInUpDirective } from './directives/fade-in-up.directive';
import { ProjectComponent } from './project/project.component';
import { ProjectsComponent } from './projects/projects.component';
import { PHisabionlineComponent } from './p-hisabionline/p-hisabionline.component';
import { PSystemComponent } from './p-system/p-system.component';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { BlogsComponent } from './blogs/blogs.component';
import { BlogComponent } from './blog/blog.component';
import { BlogResolver, BlogsResolver } from './resolvers/news_blogs.resolver';
import { ContactComponent } from './contact/contact.component';
import { register } from 'swiper/element/bundle';
import { OurServicesComponent } from './our-services/our-services.component';
import { FabWhatsAppIconComponent } from './fab-whats-app-icon/fab-whats-app-icon.component';
import { LoadingComponent } from './loading/loading.component';
import { MetaDatgsResolver } from './resolvers/metaData.resolver';
import { TestComponent } from './test/test.component';
// Swiper
register();

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    DeferLoadDirective,
    FadeInUpDirective,
    ProjectComponent,
    ProjectsComponent,
    PHisabionlineComponent,
    PSystemComponent,
    BlogsComponent,
    BlogComponent,
    ContactComponent,
    OurServicesComponent,
    FabWhatsAppIconComponent,
    LoadingComponent,
    TestComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatButtonModule,
    MatTooltipModule,
    MatMenuModule,
    MatInputModule,
    MatFormFieldModule,
    MatSidenavModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslocoRootModule,


  ],
  providers: [
    BlogResolver,
    BlogsResolver,
    MetaDatgsResolver,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
