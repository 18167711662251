import { Component, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';
import { BlogClass } from 'app/shared/blogs';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})

export class BlogComponent implements OnInit, OnDestroy {
  activeLang: string = 'ar';
  blog: BlogClass;
  private langChangesSubscription: Subscription;

  constructor(
    public router: ActivatedRoute,
    private _translocoService: TranslocoService,
    private sanitizer: DomSanitizer,) { }

  ngOnInit(): void {
    if (typeof document === 'undefined') {
      return;
    }
    this.router.data.subscribe((data) => {
      this.blog = data.blog;
    });
    this.getActiveLang();
  }

  getActiveLang() {
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }

}
