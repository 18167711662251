import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { GeneralService } from 'app/services/general.service';
import { MetaDataSchemaService } from 'app/services/metadataschema.service';
import { BlogClass } from 'app/shared/blogs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})


@Injectable({ providedIn: 'root' })
export class BlogResolver implements Resolve<BlogClass> {
    constructor(private generalService: GeneralService, private metaDataSchemaService: MetaDataSchemaService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<BlogClass> {
        const id = route.params['id'];
        const lang = route.params['lang'];
        return this.generalService.getItem('blogs', id).pipe(
            map((blog: any) => {                
                this.metaDataSchemaService.setBlogMetaTags(lang, blog as BlogClass);
                return blog as BlogClass; // Assuming BlogClass is the correct type for your blog objects
            })
        );
    }
}


@Injectable({ providedIn: 'root' })
export class BlogsResolver implements Resolve<any[]> {
    constructor(private generalService: GeneralService, private metaDataSchemaService: MetaDataSchemaService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<any[]> {
        const lang = route.params['lang'];
        this.metaDataSchemaService.setBlogsMetaTags(lang);
        return this.generalService.getItems('blogs'); // Send as query parameter
    }
}



