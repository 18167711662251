<nav
  class="top-0 fixed bg-[#020617] border-b-2 border-[#292929] lg:border-transparent w-full py-1 px-3 lg:px-24 z-50"
>
  <div class="flex items-center justify-between">
    <button
      class="block lg:hidden cursor-pointer mr-3 ml-2"
      (click)="toggleSidenav()"
    >
      <i class="fas fa-bars text-white text-2xl"></i>
      <!-- <i *ngIf="isSideNavOpen" class="fa-solid fa-xmark text-white text-2xl"></i> -->
    </button>
    <a
      class="flex items-center"
      [routerLink]="[activeLang, 'home']"
      [attr.href]="['/' + activeLang, 'home'].join('/')"
    >
      <img src="assets/homePage/HLogo.png" alt="Logo" class="w-32 py-2" />
    </a>
    <div
      class="items-center hidden lg:flex"
      [ngClass]="activeLang === 'ar' ? 'cairo text-right' : 'roboto text-left'"
      [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
    >
      <a
        [routerLink]="[activeLang, 'home']"
        [attr.href]="['/' + activeLang, 'home'].join('/')"
        routerLinkActive="active-link"
        class="mr-8 text-xl linkWithUnderline cairo"
      >
        {{ "homePage" | transloco }}
      </a>
      <a
        [routerLink]="[activeLang, 'projects']"
        [attr.href]="['/' + activeLang, 'projects'].join('/')"
        routerLinkActive="active-link"
        class="mr-8 text-xl linkWithUnderline"
      >
        {{ "projects" | transloco }}
      </a>
      <a
        [routerLink]="[activeLang, 'services']"
        [attr.href]="['/' + activeLang, 'services'].join('/')"
        routerLinkActive="active-link"
        class="mr-8 text-xl linkWithUnderline cairo"
      >
        {{ "services" | transloco }}
      </a>
      <a
        [routerLink]="[activeLang, 'blogs']"
        [attr.href]="['/' + activeLang, 'blogs'].join('/')"
        routerLinkActive="active-link"
        class="mr-8 text-xl linkWithUnderline"
      >
        {{ "suggestedBlogs" | transloco }}
      </a>
      <a
        [routerLink]="[activeLang, 'contact']"
        [attr.href]="['/' + activeLang, 'contact'].join('/')"
        routerLinkActive="active-link"
        class="mr-8 text-xl linkWithUnderline"
      >
        {{ "contact" | transloco }}
      </a>
    </div>

    <div>
      <button mat-icon-button [matMenuTriggerFor]="languages">
        <ng-container
          *ngTemplateOutlet="flagImage; context: { $implicit: activeLang }"
        ></ng-container>
      </button>
      <mat-menu [xPosition]="'before'" #languages="matMenu">
        <ng-container *ngFor="let lang of availableLangs; trackBy: trackByFn">
          <button mat-menu-item (click)="setActiveLang(lang.id)">
            <div class="flex items-center">
              <ng-container
                *ngTemplateOutlet="flagImage; context: { $implicit: lang.id }"
              ></ng-container>
              <span class="ml-3" [ngClass]="lang.font">
                {{ lang.label }}
              </span>
            </div>
          </button>
        </ng-container>
      </mat-menu>
    </div>
  </div>
</nav>
<ng-template let-lang #flagImage>
  <img
    class="w-6"
    [src]="'assets/flags/' + flagCodes[lang] + '.svg'"
    [alt]="'Flag image for ' + lang"
  />
</ng-template>
