import { Injectable } from '@angular/core';
import {
    Resolve,
    ActivatedRouteSnapshot
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { MetaDataSchemaService } from 'app/services/metadataschema.service';

@Injectable({
    providedIn: 'root'
})


@Injectable({ providedIn: 'root' })
export class MetaDatgsResolver implements Resolve<boolean> {
    constructor(private metaDataSchemaService: MetaDataSchemaService) { }
    resolve(route: ActivatedRouteSnapshot): Observable<boolean> {
        const lang = route.params['lang'];
        const page = route.data['pageName'];
        if (page === 'home') {
            this.metaDataSchemaService.setHomeMetaTags(lang);
        } else if (page === 'contact') {
            this.metaDataSchemaService.setContactMetaTags(lang);
        } else if (page === 'services'){
            this.metaDataSchemaService.setOurServicesMetaTags(lang);
        } else {
            this.metaDataSchemaService.setHomeMetaTags(lang);
        }
        
        return of(true);
    }
    
}


