import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})

export class MetaDataSchemaService {
  private renderer: Renderer2;
  private scriptElement = null;

  constructor(
    private meta: Meta,
    private titleService: Title,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = this.rendererFactory.createRenderer(null, null);
  }

  updateTagsSchema(activeLang: string, route: string, lang: string, descriptions: { [key: string]: string }, titles: { [key: string]: string }, keywords: { [key: string]: string }, schemaContent: { [key: string]: any }) {
    this.titleService.setTitle(titles[activeLang]);
    if (this.scriptElement) {
      this.renderer.removeChild(document.head, this.scriptElement);
      this.scriptElement = null;
    }
    this.meta.removeTag('rel=canonical');
    this.meta.addTag({ rel: 'canonical', href: `https://hazemdev.com/${activeLang}/${route}` });
    this.meta.updateTag({ name: 'description', content: descriptions[activeLang] });
    this.meta.updateTag({ name: 'keywords', content: keywords[activeLang] });
    this.meta.updateTag({ property: 'og:title', content: titles[activeLang] });
    this.meta.updateTag({ property: 'og:description', content: descriptions[activeLang] });
    this.meta.updateTag({ property: 'og:url', content: `https://hazemdev.com/${activeLang}/${route}` });
    this.meta.updateTag({ property: 'og:image', content: 'https://server.hazemdev.com/images/shareHazem.jpg' });
    this.meta.updateTag({ property: 'og:locale', content: activeLang === 'ar' ? 'ar_AR' : 'en_US' });
    this.meta.updateTag({ name: 'twitter:title', content: titles[activeLang] });
    this.meta.updateTag({ name: 'twitter:description', content: descriptions[activeLang] });
    this.meta.updateTag({ name: 'twitter:image', content: 'https://server.hazemdev.com/images/shareHazem.jpg' });
    this.meta.updateTag({ name: 'hreflang', content: lang, href: `https://hazemdev.com/${lang}/${route}` });
    if (typeof document === 'undefined') {
      return;
    }
    const script = this.renderer.createElement('script');
    script.type = 'application/ld+json';
    script.text = JSON.stringify(schemaContent[activeLang]);
    this.scriptElement = script;
    this.renderer.appendChild(document.head, script);
  }

  setHomeMetaTags(activeLang: string): void {
    const titles = {
      en: 'Hazem | Full Stack Web Developer',
      ar: 'حازم | مطور ويب'
    };

    const descriptions = {
      en: 'Hazem is a creative full-stack web developer with over 7 years of experience, specializing in Angular, SSR, MongoDB, NodeJS Express.',
      ar: 'حازم هو مطور ويب ومبدع يتمتع بخبرة تزيد عن 7 سنوات، متخصص في Angular, SSR, MongoDB, NodeJS Express.'
    };

    const keywords = {
      en: 'full-stack web developer, programmer, Angular, SSR, MongoDB, NodeJS Express',
      ar: 'مطور ويب, Angular, SSR, MongoDB, NodeJS Express, مبرمج'
    };

    let lang = activeLang === 'ar' ? 'en' : 'ar';
    const schemaContent = {
      en: {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": "Hazem Dev",
        "url": "https://hazemdev.com",
        "logo": "https://server.hazemdev.com/images/smLogo.png",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://hazemdev.com/search?q={search_term_string}",
          "query-input": "required name=search_term_string"
        }
      },
      ar: {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": "حازم ديف",
        "url": "https://hazemdev.com",
        "logo": "https://server.hazemdev.com/images/smLogo.png",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://hazemdev.com/search?q={search_term_string}",
          "query-input": "required name=search_term_string"
        }
      }
    };
    this.updateTagsSchema(activeLang, 'home', lang, descriptions, titles, keywords, schemaContent);
  }

  setContactMetaTags(activeLang: string): void {
    const titles = {
      en: 'Contact | Hazem',
      ar: 'التواصل | حازم'
    };

    const descriptions = {
      en: 'Get in touch with Hazem, a full-stack web developer specializing in Angular, SSR, MongoDB, NodeJS Express. Contact for web development services.',
      ar: 'تواصل مع حازم، مطور ويب متخصص في Angular, SSR, MongoDB, NodeJS Express. اتصل للحصول على خدمات تطوير الويب.'
    };

    const keywords = {
      en: 'contact, web developer, Angular, SSR, MongoDB, NodeJS Express',
      ar: 'تواصل, مطور ويب, Angular, SSR, MongoDB, NodeJS Express'
    };
    const schemaContent = {
      en: {
        "@context": "https://schema.org",
        "@type": "ContactPage",
        "name": "Contact Hazem",
        "url": `https://hazemdev.com/${activeLang}/contact`,
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+90-539-470-82-18",
          "contactType": "Customer Service"
        }
      },
      ar: {
        "@context": "https://schema.org",
        "@type": "ContactPage",
        "name": "اتصل بحازم",
        "url": `https://hazemdev.com/${activeLang}/contact`,
        "contactPoint": {
          "@type": "ContactPoint",
          "telephone": "+90-539-470-82-18",
          "contactType": "خدمة العملاء"
        }
      }
    };
    let lang = activeLang === 'ar' ? 'en' : 'ar';
    this.updateTagsSchema(activeLang, 'contact', lang, descriptions, titles, keywords, schemaContent);
  }

  setOurServicesMetaTags(activeLang: string): void {
    const titles = {
      en: 'Hazem | Our Services',
      ar: 'حازم | خدماتنا'
    };

    const descriptions = {
      en: 'Explore Hazem’s professional services: Custom website development, project consultations, and online courses in web programming using Angular CLI, NodeJS Express, and MongoDB.',
      ar: 'استكشف خدمات حازم المهنية: تطوير مواقع مخصصة، استشارات المشروعات، ودورات تدريبية عبر الإنترنت في برمجة الويب باستخدام Angular CLI, NodeJS Express, و MongoDB.'
    };

    const keywords = {
      en: 'web development, consultations, online courses, Angular, NodeJS, MongoDB, custom websites, electronic stores, management systems',
      ar: 'تطوير الويب, استشارات, دورات تدريبية عبر الإنترنت, Angular, NodeJS, MongoDB, مواقع مخصصة, متاجر إلكترونية, أنظمة إدارة'
    };

    const schemaContent = {
      en: {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Hazem's Services",
        "description": "Explore Hazem’s professional services: Custom website development, project consultations, and online courses in web programming using Angular CLI, NodeJS Express, and MongoDB.",
        "provider": {
          "@type": "Organization",
          "name": "Hazem Dev"
        },
        "offers": [
          {
            "@type": "Offer",
            "url": "https://hazemdev.com/en/services",
            "priceCurrency": "USD",
            "price": "99.99",
            "availability": "https://schema.org/InStock",
            "validFrom": "2024-01-01",
            "category": "Technology"
          }
        ]
      },
      ar: {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "خدمات حازم",
        "description": "استكشف خدمات حازم المهنية: تطوير مواقع مخصصة، استشارات المشروعات، ودورات تدريبية عبر الإنترنت في برمجة الويب باستخدام Angular CLI, NodeJS Express, و MongoDB.",
        "provider": {
          "@type": "Organization",
          "name": "حازم ديف"
        },
        "offers": [
          {
            "@type": "Offer",
            "url": "https://hazemdev.com/ar/services",
            "priceCurrency": "USD",
            "price": "99.99",
            "availability": "https://schema.org/InStock",
            "validFrom": "2024-01-01",
            "category": "التكنولوجيا"
          }
        ]
      }
    };

    let lang = activeLang === 'ar' ? 'en' : 'ar';
    this.updateTagsSchema(activeLang, 'services', lang, descriptions, titles, keywords, schemaContent);
  }



  setProjectsMetaTags(activeLang: string): void {
    const titles = {
      en: 'Projects | Hazem',
      ar: 'المشاريع | حازم'
    };

    const descriptions = {
      en: 'Explore projects by Hazem, a full-stack web developer with expertise in Angular, SSR, MongoDB, NodeJS Express. Check out ERP systems, online stores, and more.',
      ar: 'استكشف مشاريع حازم، وهو مطور ويب يتمتع بخبرة في Angular وSSR وMongoDB وNodeJS Express. اطلع على أنظمة تخطيط موارد المؤسسات (ERP)، والمتاجر عبر الإنترنت، والمزيد..'
    };

    const keywords = {
      en: 'projects, web development, programmer, ERP systems, Accounting programs, online stores, Angular, SSR, MongoDB, NodeJS Express',
      ar: 'متاجر الكترونية, برامج محاسبية ,مطور ويب , مبرمج , انظمة اي ار بي , مشاريع, Angular, SSR, MongoDB, NodeJS Express'
    };

    const schemaContent = {
      en: {
        "@context": "https://schema.org",
        "@type": "CollectionPage",
        "name": "Projects by Hazem",
        "url": `https://hazemdev.com/${activeLang}/projects`,
        "hasPart": [
          {
            "@type": "CreativeWork",
            "name": "Angular SSR",
            "url": `https://hazemdev.com/${activeLang}/administrative_system/6649481928250f0f7262fe60`
          },
          {
            "@type": "CreativeWork",
            "name": "Online Store",
            "url": `https://hazemdev.com/${activeLang}/online_stor/664550d228250f0f7262fe14`
          },
          {
            "@type": "CreativeWork",
            "name": "Administrative System",
            "url": `https://hazemdev.com/${activeLang}/administrative_system/663ff193e16cba7a2b340e4a`
          },
          {
            "@type": "CreativeWork",
            "name": "Accounting Program",
            "url": `https://hazemdev.com/${activeLang}/accounting_program/663feed6e16cba7a2b340e46`
          },
          {
            "@type": "CreativeWork",
            "name": "ERP System",
            "url": `https://hazemdev.com/${activeLang}/administrative_system/66452f3628250f0f7262fdc3`
          }
        ]
      },
      ar: {
        "@context": "https://schema.org",
        "@type": "CollectionPage",
        "name": "مشاريع حازم",
        "url": `https://hazemdev.com/${activeLang}/projects`,
        "hasPart": [
          {
            "@type": "CreativeWork",
            "name": "Angular SSR",
            "url": `https://hazemdev.com/${activeLang}/administrative_system/6649481928250f0f7262fe60`
          },
          {
            "@type": "CreativeWork",
            "name": "متاجر الكترونية",
            "url": `https://hazemdev.com/${activeLang}/online_stor/664550d228250f0f7262fe14`
          },
          {
            "@type": "CreativeWork",
            "name": "نطام إدارة",
            "url": `https://hazemdev.com/${activeLang}/administrative_system/663ff193e16cba7a2b340e4a`
          },
          {
            "@type": "CreativeWork",
            "name": "برنامج محاسبة",
            "url": `https://hazemdev.com/${activeLang}/accounting_program/663feed6e16cba7a2b340e46`
          },
          {
            "@type": "CreativeWork",
            "name": "ERP System",
            "url": `https://hazemdev.com/${activeLang}/administrative_system/66452f3628250f0f7262fdc3`
          }
        ]
      }
    };
    let lang = activeLang === 'ar' ? 'en' : 'ar';
    this.updateTagsSchema(activeLang, 'projects', lang, descriptions, titles, keywords, schemaContent);
  }

  setBlogsMetaTags(activeLang: string): void {
    const titles = {
      en: 'Hazem | Blogs',
      ar: 'حازم | المدونات'
    };

    const descriptions = {
      en: 'Explore articles and insights from Hazem, a creative full-stack web developer with over 7 years of experience.',
      ar: 'استكشف المقالات والرؤى من حازم، مطور ويب مبدع يتمتع بخبرة تزيد عن 7 سنوات.'
    };

    const keywords = {
      en: 'web development blog, Angular, SSR, MongoDB, NodeJS Express',
      ar: 'مدونة تطوير الويب, Angular, SSR, MongoDB, NodeJS Express'
    };

    const schemaContent = {
      en: {
        "@context": "https://schema.org",
        "@type": "Blog",
        "name": "Hazem's Blogs",
        "url": `https://hazemdev.com/${activeLang}/blogs`
      },
      ar: {
        "@context": "https://schema.org",
        "@type": "Blogs",
        "name": "مدونات حازم",
        "url": `https://hazemdev.com/${activeLang}/blogs`
      }
    };
    let lang = activeLang === 'ar' ? 'en' : 'ar';
    this.updateTagsSchema(activeLang, 'blogs', lang, descriptions, titles, keywords, schemaContent);
  }

  setBlogMetaTags(activeLang: string, blog: any): void {

    const titles = {
      en: blog.title['en'],
      ar: blog.title['ar']
    };

    const descriptions = {
      en: blog.description['en'],
      ar: blog.description['ar']
    };

    const keywords = {
      en: blog.keywords['en'],
      ar: blog.keywords['ar']
    };

    const schemaContent = {
      en: {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "headline": blog.title['en'],
        "description": blog.description['en'],
        "image": blog.image.url, // Replace with your image URL
        "author": {
          "@type": "Person",
          "name": "Hazem"
        },
        "publisher": {
          "@type": "Organization",
          "name": "Hazem Dev",
          "logo": {
            "@type": "ImageObject",
            "url": "https://server.hazemdev.com/images/shareLogo.jpg" // Replace with your logo URL
          }
        },
        "datePublished": blog.createdAt
      },
      ar: {
        "@context": "https://schema.org",
        "@type": "BlogPosting",
        "headline": blog.title['ar'],
        "description": blog.description['ar'],
        "image": blog.image.url, // Replace with your image URL
        "author": {
          "@type": "Person",
          "name": "حازم"
        },
        "publisher": {
          "@type": "Organization",
          "name": "حازم ديف",
          "logo": {
            "@type": "ImageObject",
            "url": "https://server.hazemdev.com/images/shareLogo.jpg" // Replace with your logo URL
          }
        },
        "datePublished": blog.createdAt
      }
    };
    let lang = activeLang === 'ar' ? 'en' : 'ar';
    this.updateTagsSchema(activeLang, `blog/${blog.seoURL}`, lang, descriptions, titles, keywords, schemaContent);
  }

  setOnlineStoreMetaTags(activeLang: string, project: any): void {
    const titles = {
      en: 'Hazem | Online Store Development',
      ar: 'حازم | تطوير متجر إلكتروني'
    };

    const descriptions = {
      en: 'Creating custom online stores tailored to meet business needs and enhance customer experience.',
      ar: 'إنشاء متاجر إلكترونية مخصصة لتلبية احتياجات الأعمال وتحسين تجربة العملاء.'
    };

    const keywords = {
      en: 'online store, e-commerce development, custom online stores, web development, e-commerce solutions',
      ar: 'متجر إلكتروني, تطوير التجارة الإلكترونية, متاجر إلكترونية مخصصة, تطوير الويب, حلول التجارة الإلكترونية'
    };

    const schemaContent = {
      en: {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Online Store Development",
        "description": "Creating custom online stores tailored to meet business needs and enhance customer experience.",
        "provider": {
          "@type": "Organization",
          "name": "Hazem Dev"
        },
        "serviceType": "WebApplication"
      },
      ar: {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "تطوير متجر إلكتروني",
        "description": "إنشاء متاجر إلكترونية مخصصة لتلبية احتياجات الأعمال وتحسين تجربة العملاء.",
        "provider": {
          "@type": "Organization",
          "name": "حازم ديف"
        },
        "serviceType": "WebApplication"
      }
    };
    let lang = activeLang === 'ar' ? 'en' : 'ar';
    this.updateTagsSchema(activeLang, `stores/${project.seoURL}`, lang, descriptions, titles, keywords, schemaContent);
  }

  setAdminstrativeSystemMetaTags(activeLang: string, system: any): void {
    const allProjects = {
      website_angular_ssr: {
        titles: {
          en: 'Hazem | Angular SSR Development',
          ar: 'حازم | تطوير Angular SSR'
        },
        descriptions: {
          en: 'Developing high-performance server-side rendered Angular applications to enhance user experience and SEO.',
          ar: 'تطوير تطبيقات Angular عالية الأداء المعروضة من جانب الخادم لتحسين تجربة المستخدم وتحسين SEO.'
        },
        keywords: {
          en: 'Angular SSR, server-side rendering, web development, Angular applications, high-performance apps, SEO optimization',
          ar: 'Angular SSR, عرض من جانب الخادم, تطوير الويب, تطبيقات Angular, تطبيقات عالية الأداء, تحسين SEO'
        }
      },
      erp_system: {
        titles: {
          en: 'Hazem | ERP System Development',
          ar: 'حازم | تطوير نظام ERP'
        },
        descriptions: {
          en: 'Creating comprehensive ERP systems to manage all aspects of business operations effectively.',
          ar: 'إنشاء أنظمة ERP شاملة لإدارة جميع جوانب العمليات التجارية بفعالية.'
        },
        keywords: {
          en: 'ERP system, enterprise resource planning, business management, web development, custom ERP solutions',
          ar: 'نظام ERP, تخطيط موارد المؤسسات, إدارة الأعمال, تطوير الويب, حلول ERP مخصصة'
        }
      },
      process_automation: {
        titles: {
          en: 'Hazem | Administrative System Development',
          ar: 'حازم | تطوير نظام إداري'
        },
        descriptions: {
          en: 'Developing administrative systems for efficient business management and process automation.',
          ar: 'تطوير أنظمة إدارية لإدارة الأعمال بكفاءة وأتمتة العمليات.'
        },
        keywords: {
          en: 'administrative system, business management, process automation, web development, custom solutions',
          ar: 'نظام إداري, إدارة الأعمال, أتمتة العمليات, تطوير الويب, حلول مخصصة'
        }
      },
      universities_exams: {
        titles: {
          en: 'Hazem | Educational platform',
          ar: 'حازم | منصة تعليمية'
        },
        descriptions: {
          en: 'Developing educational platforms for exams and training lessons.',
          ar: 'تطوير منصات تعليمية لإجراء الامتحانات وحضور دروس تدريبية.'
        },
        keywords: {
          en: 'educational platform, education, exam, quiz, university, college',
          ar: 'منصة تعليمية, تعليم, امتحان, اختبار, جامعة, كلية'
        }
      }
    };
    const project = allProjects[system];
    const schemaContent = {
      en: {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": project.titles['en'],
        "description": project.descriptions['en'],
        "provider": {
          "@type": "Organization",
          "name": "Hazem Dev"
        },
        "serviceType": "SoftwareApplication"
      },
      ar: {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": project.titles['ar'],
        "description": project.descriptions['ar'],
        "provider": {
          "@type": "Organization",
          "name": "حازم ديف"
        },
        "serviceType": "SoftwareApplication"
      }
    };
    let lang = activeLang === 'ar' ? 'en' : 'ar';
    this.updateTagsSchema(activeLang, `administrative_system/${system}`, lang, project.descriptions, project.titles, project.keywords, schemaContent);
  }

  setAccountingProgramMetaTags(activeLang: string): void {
    const titles = {
      en: 'Hazem | Accounting Program Development',
      ar: 'حازم | تطوير برنامج محاسبة'
    };

    const descriptions = {
      en: 'Building accounting programs to streamline financial operations and improve accuracy.',
      ar: 'بناء برامج محاسبة لتبسيط العمليات المالية وتحسين الدقة.'
    };

    const keywords = {
      en: 'accounting program, financial software, web development, custom accounting solutions, financial management',
      ar: 'برنامج محاسبة, برامج مالية, تطوير الويب, حلول محاسبية مخصصة, إدارة مالية'
    };

    const schemaContent = {
      en: {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "Accounting Program Development",
        "description": "Building accounting programs to streamline financial operations and improve accuracy.",
        "provider": {
          "@type": "Organization",
          "name": "Hazem Dev"
        },
        "serviceType": "SoftwareApplication"
      },
      ar: {
        "@context": "https://schema.org",
        "@type": "Service",
        "name": "تطوير برنامج محاسبة",
        "description": "بناء برامج محاسبة لتبسيط العمليات المالية وتحسين الدقة.",
        "provider": {
          "@type": "Organization",
          "name": "حازم ديف"
        },
        "serviceType": "SoftwareApplication"
      }
    };
    let lang = activeLang === 'ar' ? 'en' : 'ar';
    this.updateTagsSchema(activeLang, 'accounting_program/accounting_system_program_erp', lang, descriptions, titles, keywords, schemaContent);
  }
}
