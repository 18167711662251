import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FormService {

  private tasksSubject = new BehaviorSubject<any[]>([]);
  tasks$: Observable<any[]> = this.tasksSubject.asObservable();

  constructor() { }


  addItem(item: string) {
    const currentTasks = this.tasksSubject.value;
    this.tasksSubject.next([...currentTasks, item]);
  }

  deleteItem(item: string) {
    const currentTasks = this.tasksSubject.value;
    this.tasksSubject.next([...currentTasks.filter(el => el !== item)]);
  }


}
