import { Component, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LanguageService } from '../services/language.service';
import { SidenavService } from 'app/services/sidenav.service';
import { AppComponent } from 'app/app.component';

interface AvailableLang {
  id: string;
  label: string;
  font: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],

})

export class HeaderComponent implements OnInit {
  activeLang: string = 'ar';
  // isSideNavOpen: boolean = false;
  availableLangs: AvailableLang[] = [
    {
      "id": "en",
      "label": "English",
      "font": "roboto"
    },
    {
      "id": "ar",
      "label": "العربية",
      "font": "cairo"
    }
  ];
  flagCodes: any;

  constructor(private _translocoService: TranslocoService, private langService: LanguageService,
    private sidenavS: SidenavService,
    private appComponent: AppComponent,) { }


  ngOnInit(): void {
    this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
    this.flagCodes = {
      'en': 'en',
      'ar': 'ar',
    };
  }

  setActiveLang(lang: string): void {
    this._translocoService.setActiveLang(lang);
    this.langService.setLanguage(lang);
    localStorage.setItem('usedLang', lang)
  }

  trackByFn(index: number, item: any): any {
    return item.id || index;
  }

  toggleSidenav() {
    const sidenav = this.appComponent.sidenavNav;
    this.sidenavS.setSidenav(sidenav);
    this.sidenavS.toggle();
    // this.isSideNavOpen = !this.isSideNavOpen;
  }

}
