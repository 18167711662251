<div *ngIf="blog" class="mt-28" [ngClass]="activeLang === 'ar' ? 'cairo' : 'roboto'">
    <div class="flex items-center justify-center mt-10">
      <h1
        class="text-center inline-block mr-zero"
        [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
      >
        {{ blog.title[activeLang] }}
      </h1>
    </div>
    <div class="w-2/3 center my-10">
      <svg
        width="100%"
        height="3"
        viewBox="0 0 1230 3"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          y1="1.5"
          x2="1230"
          y2="1.5"
          stroke="url(#paint0_linear_22_145)"
          stroke-width="3"
        />
        <defs>
          <linearGradient
            id="paint0_linear_22_145"
            x1="0"
            y1="3"
            x2="1230"
            y2="3"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#49AEAC" />
            <stop offset="0.52" stop-color="white" />
            <stop offset="1" stop-color="#49AEAC" />
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div class="px-2 lg:px-20 xl:px-20">
      <div class="bg-[#dedede17] shadow-md rounded-lg grid items-center p-5">
        <div class="w-full mt-5" [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'">
          <span [innerHTML]="getSafeHtml(blog.description[activeLang])"></span>
        </div>
      </div>
    </div>
  <div class="my-60"></div>
</div>
