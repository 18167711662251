import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: ['./our-services.component.scss']
})
export class OurServicesComponent implements OnInit, OnDestroy {
  activeLang: string = 'ar';
  private langChangesSubscription: Subscription;

  constructor(
    private _translocoService: TranslocoService,
  ) { }

  ngOnInit(): void {
    if (typeof document === 'undefined') {
      return; // Not running in the browser, do nothing
    }
    this.langChangesSubscription = this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  ngOnDestroy() {
    if (this.langChangesSubscription) {
      this.langChangesSubscription.unsubscribe();
    }
  }
}
