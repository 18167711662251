<div
  class="mt-14"
  [ngClass]="activeLang === 'ar' ? 'cairo' : 'roboto'"
>
  <div
    class="flex items-center justify-center"
    [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
  >
    <p class="text-center pt-10 inline-block text-3xl">
      {{ project.name[activeLang] }}
      <svg
        class="mt-2"
        width="87"
        height="2"
        viewBox="0 0 87 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="1" x2="87" y2="1" stroke="#49AEAC" stroke-width="2" />
      </svg>
    </p>
  </div>
  <p class="text-center text-base px-2 lg:px-16 xl:px-40 mute pt-3">
    {{ project.longDes[activeLang] }}
  </p>
  <svg
    class="my-16"
    width="100%"
    height="3"
    viewBox="0 0 1230 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="1.5"
      x2="1230"
      y2="1.5"
      stroke="url(#paint0_linear_22_145)"
      stroke-width="3"
    />
    <defs>
      <linearGradient
        id="paint0_linear_22_145"
        x1="0"
        y1="3"
        x2="1230"
        y2="3"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#49AEAC" />
        <stop offset="0.52" stop-color="white" />
        <stop offset="1" stop-color="#49AEAC" />
      </linearGradient>
    </defs>
  </svg>
  <div class="grid items-center justify-center pb-6 px:2 lg:px-32">
    <p class="text-center text-2xl">
      {{ "someMainTitles" | transloco }}
    </p>
  </div>
  <div
    class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 px-2 lg:px-10 xl:px-20"
    [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
  >
    <div
      *ngFor="let feature of project.properties"
      class="p-4 bg-[#091627] text-white rounded-3xl shadow-md"
    >
      <div
        class="flex items-center justify-center bg-[#49AEAC] rounded-full w-10 h-10 text-[#020617] text-lg mb-4"
      >
        <i [class]="feature.icon"></i>
      </div>
      <p class="text-xl font-semibold mb-2">{{ feature.title[activeLang] }}</p>
      <p class="mute">{{ feature.description[activeLang] }}</p>
    </div>
  </div>
  <p class="text-center text-xl pt-14">
    {{ "iPlayed" | transloco }}
  </p>
  <svg
    class="my-20"
    width="100%"
    height="3"
    viewBox="0 0 1230 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="1.5"
      x2="1230"
      y2="1.5"
      stroke="url(#paint0_linear_22_145)"
      stroke-width="3"
    />
    <defs>
      <linearGradient
        id="paint0_linear_22_145"
        x1="0"
        y1="3"
        x2="1230"
        y2="3"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#49AEAC" />
        <stop offset="0.52" stop-color="white" />
        <stop offset="1" stop-color="#49AEAC" />
      </linearGradient>
    </defs>
  </svg>
  <div
    class="flex items-center justify-center"
    [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
  >
    <p class="text-center text-2xl inline-block">
      {{ "someSnapshots" | transloco }}
      <svg
        class="mt-1"
        width="150"
        height="2"
        viewBox="0 0 150 2"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line y1="1" x2="150" y2="1" stroke="#49AEAC" stroke-width="2" />
      </svg>
    </p>
  </div>
  <div class="mt-4">
    <img
    *ngIf="project.projectImages && project.projectImages[index]"
    class="w-full md:w-11/12 lg:w-10/12 xl:w-9/12 mx-auto"
    [src]="project.projectImages[index].url"
    loading="lazy"
    alt="Image"
  />
  </div>
  <div class="grid grid-cols-5 lg:flex items-center justify-evenly mt-10 px-2 md:px-14">
    <a
      *ngFor="let image of project.projectImages; let i = index"
      (click)="setImageIndex(i)"
      [matTooltip]="image.name[activeLang]"
    >
      <img
        class="w-11/12 lg:w-2/3 mb-5 lg:mb-0 mx-auto"
        [src]="image.url"
        [class.mask]="!isActive(i)"
        loading="lazy"
        alt="Image"
      />
    </a>
  </div>
  <svg
    class="my-16"
    width="100%"
    height="3"
    viewBox="0 0 1230 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <line
      y1="1.5"
      x2="1230"
      y2="1.5"
      stroke="url(#paint0_linear_22_145)"
      stroke-width="3"
    />
    <defs>
      <linearGradient
        id="paint0_linear_22_145"
        x1="0"
        y1="3"
        x2="1230"
        y2="3"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#49AEAC" />
        <stop offset="0.52" stop-color="white" />
        <stop offset="1" stop-color="#49AEAC" />
      </linearGradient>
    </defs>
  </svg>
  <section class="w-full">
    <div
      class="w-full flex justify-center mx-auto px-4"
      [dir]="activeLang === 'ar' ? 'rtl' : 'ltr'"
    >
      <p class="text-center inline-block text-2xl">
        {{ "technologiesUsed" | transloco }}
        <svg
          class="mt-3"
          width="87"
          height="2"
          viewBox="0 0 87 2"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <line y1="1" x2="87" y2="1" stroke="#49AEAC" stroke-width="2" />
        </svg>
      </p>
    </div>
    <div class="w-full sm:w-10/12 lg:w-9/12 xl:w-1/2 mx-auto mt-10">
      <img
      src="assets\project\TUsedTailwind.svg"
      width="100%"
      loading="lazy"
      alt="Image"
    />
    </div>

  </section>
</div>
