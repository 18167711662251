import {
  provideTransloco,
  TranslocoModule
} from '@ngneat/transloco';
import { NgModule } from '@angular/core';
import { TranslocoHttpLoader } from './transloco-loader';

@NgModule({
  exports: [TranslocoModule],
  providers: [
    provideTransloco({
      config: {
        availableLangs: [{ id: 'en', label: 'English' }, { id: 'ar', label: 'العربية' }],
        defaultLang: 'ar', // Default language
        reRenderOnLangChange: true,
        prodMode: true,
        fallbackLang: 'ar' // Fallback to 'ar' if language is missing
      },
      loader: TranslocoHttpLoader
    }),
  ],
})
export class TranslocoRootModule { }
