import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { LanguageService } from './services/language.service';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './services/sidenav.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('sidenavNav') public sidenavNav: MatSidenav;
  activeLang: string = 'ar';
  isSidenavOpen = false;

  constructor(
    private _translocoService: TranslocoService,
    private langService: LanguageService,
    private sidenavService: SidenavService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    if (typeof document === 'undefined') {
      return;
    }

    // Get the saved language from localStorage or default to 'ar'
    const savedLang = localStorage.getItem('usedLang') || 'ar';

    // Get the current language from the URL
    const currentLangInUrl = this.activatedRoute.snapshot.paramMap.get('lang');

    // If the current URL language does not match the saved language, redirect to the correct language
    if (!currentLangInUrl || currentLangInUrl !== savedLang) {
      const newUrl = this.router.url.replace(/\/[a-zA-Z]{2}\//, `/${savedLang}/`);
      // Redirect to the correct language in the URL before rendering content
      this.router.navigateByUrl(newUrl, { replaceUrl: true }).then(() => {
        // After redirection, set the language for the content
        this._translocoService.setActiveLang(savedLang);
        this.langService.setLanguage(savedLang);
      });
    } else {
      // Set the language for the content if URL language matches
      this._translocoService.setActiveLang(currentLangInUrl);
      this.langService.setLanguage(currentLangInUrl);
    }

    // Subscribe to language changes to handle subsequent navigations
    this._translocoService.langChanges$.subscribe((activeLang) => {
      this.activeLang = activeLang;
    });
  }

  ngAfterViewInit(): void {
    if (typeof document === 'undefined') {
      return;
    }
    this.sidenavService.setSidenav(this.sidenavNav);
  }

  navigateToRoute() {
    this.isSidenavOpen = false;
    this.sidenavNav.close();
  }
}
